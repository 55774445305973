import { TextField, Autocomplete } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function DropdownTextField(props) {
  const classes = useStyles();
  const {
    id,
    label,
    onChange,
    options,
    values,
    noClearIcon,
    optionKey,
    disabled,
    error,
    helperText,
  } = props;

  return (
    <Autocomplete
      id={id}
      className={classes.root}
      options={options}
      getOptionLabel={(option) => option && option[optionKey]}
      value={values}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={onChange}
      clearIcon={noClearIcon}
      disabled={disabled}
    />
  );
}

DropdownTextField.propTypes = {
  id: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  values: PropTypes.object,
  noClearIcon: PropTypes.bool,
  optionKey: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
