import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import {
  ListingHeader,
  ContainerCard,
  TableMenuButton,
} from "../../components";
import { makeStyles } from "@mui/styles";
import { AddAdvertisement } from "../popups/AddAdvertisement";
import { AdvertisementTableItem } from "../../components/items/AdvertisementTableItem";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import moment from "moment";
import { debounce } from "lodash";
import NoDataCard from "../../components/cards/NoDataCard";
import LoaderCard from "../../components/cards/LoaderCard";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },

  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  // headItem: {
  //     fontWeight : 700
  //  }
}));

const menuOptions = [
  { id: 1, label: "Edit" },
  { id: 2, label: "Delete" },
];

export function Advertisements() {
  const classes = useStyles();
  const [openAddAdvertisement, setOpenAdvertisement] = useState(false);
  const [advertisement, setAdvertisement] = useState([]);
  const [languages, setLanguages] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPages] = useState(0);

  const [searchInput, setSearchInput] = useState("");

  const { showToast } = useToast();
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [selAd, setSelAd] = useState(null);
  const [confirmDelAd, setConfirmDelAd] = useState(false);

  const getAdvertisement = () => {
    setIsLoading(true);
    api
      .get(urls.advertisements, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          search_key: searchInput,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setAdvertisement(res.data.data.advertisement_list);
        setTotalPages(res.data.data.pagination.total);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getAdvertisement();
  }, [page, rowsPerPage, searchInput]);

  const getLanguages = () => {
    api
      .get(urls.languages)
      .then((res) => setLanguages(res.data.data))
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getLanguages();
  }, []);
  const delayedSearch = useCallback(
    debounce((text) => {
      setSearchInput(text);
    }, 500),
    []
  );

  const handleOnSearch = (event) => {
    delayedSearch(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleCloseAddAdvertisement = () => {
    setOpenAdvertisement(false);
    setSelAd(null);
  };
  const handleOnclickAdvertisement = () => {
    setOpenAdvertisement(true);
  };

  const handleOnSubmit = () => {
    setOpenAdvertisement(false);
    getAdvertisement();
    setSelAd(null);
  };

  const onCloseDeletion = () => {
    setSelAd(null);
    setConfirmDelAd(false);
  };

  const onConfirmDeletion = () => {
    setConfirmDelAd(false);
    api
      .delete(urls.deleteAdvertisement + selAd.id)
      .then((res) => {
        showToast("Advertisement deleted successfully", "success");
        getAdvertisement();
        setSelAd(null);
      })
      .catch((err) => {
        setSelAd(null);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ListingHeader
            title={
              totalPage > 0 ? `Advertisements (${totalPage})` : "Advertisements"
            }
            btnTitle="Add New Advertisements "
            placeholder="Search Advertisement"
            onClickPrimary={handleOnclickAdvertisement}
            onSearchChange={handleOnSearch}
          />
        </Grid>
        {isLoading && (
          <Grid item>
            <LoaderCard />
          </Grid>
        )}
        {!isLoading && (
          <Grid item>
            <Grid item>
              <ContainerCard>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.header}>
                          Advertisement
                        </TableCell>
                        <TableCell className={classes.header}>
                          Created Date
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {advertisement?.map((post, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>
                              {
                                <AdvertisementTableItem
                                  advertisementname={post.title}
                                  advertiseimg={post.image_url}
                                />
                              }
                            </TableCell>
                            <TableCell>
                              {moment(post.created_at).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell>
                              <TableMenuButton
                                options={menuOptions}
                                onOptionClick={(menu) => {
                                  setSelAd(post);
                                  if (menu.id === 1) {
                                    setOpenAdvertisement(true);
                                  } else {
                                    setConfirmDelAd(true);
                                  }
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* <TableRow sx={{ cursor: "pointer" }} hover>
                    <TableCell>
                      <AdvertisementTableItem />{" "}
                    </TableCell>
                    <TableCell>20/03/2023</TableCell>
                  </TableRow>
                  <TableRow sx={{ cursor: "pointer" }} hover>
                    <TableCell>
                      <AdvertisementTableItem />{" "}
                    </TableCell>
                    <TableCell>20/03/2023</TableCell>
                  </TableRow>
                  <TableRow sx={{ cursor: "pointer" }} hover>
                    <TableCell>
                      <AdvertisementTableItem />{" "}
                    </TableCell>
                    <TableCell>20/03/2023</TableCell>
                  </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
                {totalPage === 0 && <NoDataCard title="No Data Found" />}
                <TablePagination
                  component="div"
                  count={totalPage}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </ContainerCard>
            </Grid>
          </Grid>
        )}
      </Grid>
      {openAddAdvertisement && (
        <AddAdvertisement
          open={openAddAdvertisement}
          onClose={handleCloseAddAdvertisement}
          onSubmit={handleOnSubmit}
          languages={languages}
          isEdit={selAd !== null}
          selAd={selAd}
        />
      )}
      {confirmDelAd && (
        <ConfirmationDialog
          open={confirmDelAd}
          onClose={onCloseDeletion}
          message="Are you sure you want to delete this Advertisement?"
          onSubmit={onConfirmDeletion}
        />
      )}
    </Fragment>
  );
}
