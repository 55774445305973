import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "&.MuiPaper-root": {
      // borderRadius: 0,
    },
  },
}));

export function ContainerCard(props) {
  const classes = useStyles();
  const { children } = props;

  return <Paper className={classes.root}>{children}</Paper>;
}
