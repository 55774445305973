import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  primary: {
    width: "150px",
    "&.MuiButton-root": {
      fontSize: 16,
      fontWeight: 600,
      color: "white",
      background: theme.palette.common.blackTxt,
      borderRadius: "10px",
      "&:hover": {
        background: theme.palette.common.blackTxt,
       
      },

      height: 45,
      textTransform: "none",
      padding: theme.spacing(2),
    },
  },
  secondary: {
    width: "100%",
    "&.MuiButton-root": {
      fontSize: 16,
      fontWeight: 600,
      color: "black",
      height: 45,
      textTransform: "none",
      padding: theme.spacing(2),
      borderColor: "black",
      "&:hover": {
        borderColor: "black",
      },
    },
  },
}));

export function ItemButton(props) {
  const classes = useStyles();
  const { children, onClick, type, showIcon } = props;

  return (
    <Button
      startIcon={showIcon === true && <Add />}
      className={classes[type]}
      variant={type === "secondary" ? "outlined" : "text"}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

ItemButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  showIcon: PropTypes.bool,
};
