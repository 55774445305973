import React, { useEffect, useState } from "react";
import { Modal, Grid, Typography, Stack, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderButton, TextFieldWithDropDown } from "../../../components";
import { api, urls } from "../../../services";
import { useToast } from "../../../contexts";
// import { languages } from "../../../utilities";
import stc from "string-to-color";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    background: theme.palette.common.white,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
}));

export function AddCategory(props) {
  const classes = useStyles();
  const { open, onClose, onSubmit, languages } = props;
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    cat_name: "",
    cat_lang: 2,
    cat_color: "",
    errors: { cat_name: false },
  });

  const handleNameChange = (e) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr.cat_name = e.target.value;
      curr.cat_color = stc(e.target.value);
      curr.errors.cat_name = false;
      return curr;
    });
  };

  const handleLangChange = (e) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr.cat_lang = e.target.value.id;
      return curr;
    });
  };

  const handleSaveClick = () => {
    if (!formData.cat_name.trim()) {
      setFormData((prev) => {
        let curr = { ...prev };
        curr.errors.cat_name = true;
        return curr;
      });
    } else {
      let params = {
        colour: formData.cat_color,
        article_category_descriptions: [
          {
            name: formData.cat_name,
            description: "",
            language_id: formData.cat_lang,
          },
        ],
      };
      api
        .post(urls.createCategory, params)
        .then((res) => {
          showToast(res.data.message, "success");
          onSubmit();
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  const handleCancelClick = () => {
    onClose();
  };

  // useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>Add Category</Typography>
        <Grid container spacing={4} direction="column">
          <Grid item xs>
            <Stack spacing={4}>
              <Grid item xs>
                <TextFieldWithDropDown
                  label={"Category Name"}
                  textValue={formData.cat_name}
                  onTextChange={handleNameChange}
                  dropOptions={languages}
                  dropValue={languages.find((x) => x.id === formData.cat_lang)}
                  dropKey={"short_code"}
                  onDropChange={handleLangChange}
                  textError={formData.errors.cat_name}
                  textHelperText={
                    formData.errors.cat_name && "Please enter category name"
                  }
                />
              </Grid>
              <Stack direction="row" spacing={2}>
                <HeaderButton type="secondary" onClick={handleCancelClick}>
                  Cancel
                </HeaderButton>
                <HeaderButton type="primary" onClick={handleSaveClick}>
                  Save
                </HeaderButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

AddCategory.propsTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  languages: PropTypes.array,
};
