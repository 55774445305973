import React, { Fragment, useState } from "react";
import {
  Box,
  Stack,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  alpha,
} from "@mui/material";
import Cropper from "react-easy-crop";

import PropTypes from "prop-types";

export const ImageEasyCropper = (props) => {
  const { selectedImage, aspectRatio, closeCropper, cropCompleted } = props;

  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumSize = useMediaQuery(theme.breakpoints.down("md"));

  const [cropUpdated, setCropUpdated] = useState(true);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageBase64, setImageBase64] = useState("");

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(
        selectedImage,
        croppedAreaPixels
      );
      setImageBase64(croppedImage);
    } catch (e) {
      console.error("Error occurred while cropping the image:", e);
    }
  };

  const getCroppedImg = (imageSrc, pixelCrop, config) => {
    const { x, y, width, height } = pixelCrop;
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = width;
    canvas.height = height;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

        const base64 = canvas.toDataURL("image/jpeg");
        resolve(base64);
      };

      image.onerror = (error) => {
        reject(error);
      };
    });
  };

  const cropImageNow = () => {
    cropCompleted(imageBase64);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 300, md: 500, lg: 600 },
    boxShadow: 24,
    borderRadius: 4,
    p: {
      xs: 2.5,
      sm: 3,
      md: 4,
    },
  };

  return (
    <Fragment>
      <Modal
        open={selectedImage !== null}
        onClose={closeCropper}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height:
                  mediumSize && smallSize
                    ? "300px "
                    : mediumSize && !smallSize
                    ? "360px"
                    : "500px",
              }}
            >
              <Cropper
                image={selectedImage}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                restrictPosition={true}
              />
            </Box>

            <Stack
              direction={"row"}
              justifyContent={"space-evenly"}
              spacing={2}
              mt={"80%"}
            >
              <Button
                variant="contained"
                onClick={cropImageNow}
                disabled={!cropUpdated}
                sx={{
                  background: theme.palette.common.greenTxt,
                  "&:hover": {
                    background: theme.palette.common.greenTxt,
                  },
                  textTransform: "none",
                  width: "120px",
                }}
                disableElevation
              >
                Crop
              </Button>

              <Button
                variant="outlined"
                onClick={closeCropper}
                sx={{
                  borderColor: theme.palette.common.greenTxt,
                  color: theme.palette.common.greenTxt,
                  "&:hover": {
                    borderColor: theme.palette.common.greenTxt,
                    color: theme.palette.common.greenTxt,
                  },
                  textTransform: "none",
                  width: "120px",
                }}
                disableElevation
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </Fragment>
  );
};

ImageEasyCropper.propTypes = {
  closeCropper: PropTypes.func,
  cropCompleted: PropTypes.func,
};
