import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Box,
  Typography,
} from "@mui/material";
import {
  ListingHeader,
  ContainerCard,
  MagazineTableItem,
  StatusLabel,
  PublishedLabel,
  ArticleTableItem,
  AuthorTableItem,
} from "../../components";
import { makeStyles } from "@mui/styles";
import { AddPlan } from "../popups/AddPlan";
import dotted from "../../assets/images/dottedmore.svg";
// import { AddCategory } from "../popups/AddCategory";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import moment from "moment";
import pluralize from "pluralize";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  discount: {
    background: theme.palette.common.redTxt,
    color: "#FFFFFF",
    padding: "2px 0px 2px 8px",
    width: "50px",
    borderRadius: "20px",
    fontSize: "12px",
  },
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  border: {
    borderBottom: "0px !important",
  },
}));

export function Plans() {
  const classes = useStyles();
  const [openAddPlans, setOpenPlans] = useState(false);
  const [plans, setPlans] = useState([]);
  const { showToast } = useToast();
  const { logout } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseAddPlans = () => {
    setOpenPlans(false);
  };

  const handleOnclickPlans = () => {
    setOpenPlans(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPlans = () => {
    setIsLoading(true);
    api
      .get(urls.plans, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
        },
      })
      .then((res) => {
        setPlans(res.data.data.subscription_plans);
        setTotalPages(res.data.data.pagination.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getPlans();
  }, [page, rowsPerPage]);

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ListingHeader
            title={
              totalPage > 0
                ? `Subscription Plans (${totalPage})`
                : "Subscription Plans"
            }
            // btnTitle="Add New Plan"
            // placeholder="Search Plans"
            onClickPrimary={handleOnclickPlans}
          />
        </Grid>
        {isLoading && (
          <Grid item>
            <LoaderCard />
          </Grid>
        )}
        {!isLoading && (
          <Grid item>
            <ContainerCard>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header}>Plan</TableCell>
                      <TableCell className={classes.header}>
                        No.of Users
                      </TableCell>
                      <TableCell className={classes.header}>
                        Magazine Category
                      </TableCell>
                      <TableCell className={classes.header}>Validity</TableCell>
                      <TableCell className={classes.header}>Amount</TableCell>
                      <TableCell className={classes.header}>
                        Discounted Amount
                      </TableCell>
                      {/* <TableCell></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {plans.map((p, i) => (
                      <TableRow key={i} sx={{ cursor: "pointer" }} hover>
                        <TableCell>{p.name}</TableCell>
                        <TableCell>{p.users_count}</TableCell>
                        <TableCell>
                          {p.subscriptions[0]?.product_set_name}
                        </TableCell>
                        <TableCell>{`${
                          p.subscriptions[0]?.duration ?? 0
                        } ${pluralize(
                          "Day",
                          p.subscriptions[0]?.duration ?? 0
                        )}`}</TableCell>
                        <TableCell>{`₹${p.actual_amount}`}</TableCell>
                        <TableCell>{`₹${p.discounted_amount}`}</TableCell>
                        {/* <TableCell>
                        <img src={dotted} sx={{ alignItems: "right" }} />
                      </TableCell> */}
                      </TableRow>
                    ))}
                    {/*<TableRow sx={{ cursor: "pointer" }} hover>
                    <TableCell>Malayalam</TableCell>
                    <TableCell>40098</TableCell>
                    <TableCell>Malayalam</TableCell>
                    <TableCell>365 Days</TableCell>
                    <TableCell>₹ 1490</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>
                      <img src={dotted} sx={{ alignItems: "right" }} />
                    </TableCell>
                  </TableRow>
                   <TableRow sx={{ cursor: "pointer" }} hover>
                    <TableCell>43258</TableCell>
                    <TableCell>English</TableCell>
                    <TableCell>6056</TableCell>
                    <TableCell>English</TableCell>
                    <TableCell>200 Days</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          textDecorationLine: "line-through",
                          fontSize: "14px",
                          color: "#7E7E7E",
                        }}
                      >
                        ₹ 1500
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row">
                        <Grid item xs>
                          ₹ 1399
                        </Grid>
                        <Grid item xs>
                          <Box>
                            <Stack className={classes.discount}>7% off</Stack>
                          </Box>
                        </Grid>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <img src={dotted} sx={{ alignItems: "right" }} />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ cursor: "pointer" }} hover>
                    <TableCell>43259</TableCell>
                    <TableCell>Arabic</TableCell>
                    <TableCell>4578</TableCell>
                    <TableCell>Arabic</TableCell>
                    <TableCell>129 Days</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          textDecorationLine: "line-through",
                          fontSize: "14px",
                          color: "#7E7E7E",
                        }}
                      >
                        ₹ 1700
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row">
                        <Grid item xs>
                          ₹ 1599
                        </Grid>
                        <Grid item xs>
                          <Box>
                            <Stack className={classes.discount}>5% Off</Stack>
                          </Box>
                        </Grid>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <img src={dotted} sx={{ alignItems: "right" }} />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ cursor: "pointer" }} hover>
                    <TableCell>43260</TableCell>
                    <TableCell>Malayalam + English</TableCell>

                    <TableCell>4118 </TableCell>

                    <TableCell>
                      <Stack>
                        <Stack direction="row">
                          <TableCell>Malayalam </TableCell>
                          <TableCell>365 Days</TableCell>
                        </Stack>

                        <Stack direction="row">
                          <TableCell>English</TableCell>
                          <TableCell>149 Days</TableCell>
                        </Stack>
                      </Stack>
                    </TableCell>

                    <TableCell>
                      <Typography
                        sx={{
                          textDecorationLine: "line-through",
                          fontSize: "14px",
                          color: "#7E7E7E",
                        }}
                      >
                        ₹ 1999
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row">
                        <Grid item xs>
                          ₹ 1699
                        </Grid>
                        <Grid item xs>
                          <Box>
                            <Stack className={classes.discount}>7% off</Stack>
                          </Box>
                        </Grid>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <img src={dotted} sx={{ alignItems: "right" }} />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ cursor: "pointer" }} hover>
                    <TableCell className={classes.border}>43261</TableCell>
                    <TableCell className={classes.border}>English</TableCell>
                    <TableCell className={classes.border}>2994</TableCell>
                    <TableCell className={classes.border}>English</TableCell>
                    <TableCell className={classes.border}>179 Days</TableCell>
                    <TableCell className={classes.border}>
                      <Typography sx={{ fontSize: "14px" }}>₹ 1650</Typography>
                    </TableCell>

                    <TableCell className={classes.border}>-</TableCell>
                    <TableCell className={classes.border}>
                      <img src={dotted} sx={{ alignItems: "right" }} />
                    </TableCell>
                  </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={totalPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ContainerCard>
          </Grid>
        )}
      </Grid>
      <AddPlan open={openAddPlans} onClose={handleCloseAddPlans} />
    </Fragment>
  );
}
