import { Divider, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  plan: {
    paddingLeft: "50px",
    border: "1px solid black"
  }
}));

export function PlanValidityTextField(props) {
  const classes = useStyles();
  const { label, multiline, onChange, type } = props;

  return (
    <TextField
      id="normal-field"
      className={classes.root}
      label={label}
      multiline={multiline}
      onChange={onChange}
      type={type}
      variant="outlined"
      >
        <TextField className={classes.plan} placeholder="Days"></TextField>
  </TextField>
    
  );
}

PlanValidityTextField.propTypes = {
  label: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
};
