import { TextField, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function NormalTextField(props) {
  const classes = useStyles();
  const {
    label,
    multiline,
    disabled,
    onChange,
    type,
    value,
    error,
    helperText,
    prefix,
    suffix,
  } = props;

  return (
    <TextField
      id="normal-field"
      className={classes.root}
      label={label}
      multiline={multiline}
      onChange={onChange}
      type={type}
      variant="outlined"
      disabled={disabled}
      value={value}
      error={error}
      helperText={helperText}
      InputProps={{
        startAdornment: prefix && (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ),
        endAdornment: suffix && (
          <InputAdornment position="end">{suffix}</InputAdornment>
        ),
      }}
      onKeyDown={(e) =>
        type === "number" &&
        (e.key === "e" || e.key === "E") &&
        e.preventDefault()
      }
    />
  );
}

NormalTextField.propTypes = {
  label: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};
