import { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { VerifyEmail } from "./VerifyEmail";
import { CreatePassword } from "./CreatePassword";
import { AuthRoute } from "../../routes";

export function Authentication() {
  return (
    <Fragment>
      <Routes>
        <Route element={<AuthRoute />}>
          {/* <Route path="/" element={<Navigate to="/login" replace />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/verify" element={<VerifyEmail />} />
          <Route path="/create" element={<CreatePassword />} />
        </Route>
      </Routes>
    </Fragment>
  );
}
