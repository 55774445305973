import { useState, useEffect, useRef, Fragment } from "react";
import {
  Stack,
  Box,
  IconButton,
  Menu,
  CircularProgress,
  MenuItem,
  useTheme,
  alpha,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { profilePlaceholder } from "../../assets";
import { api, urls } from "../../services";
import { useToast } from "../../contexts";
import PropTypes from "prop-types";
import { ImageEasyCropper } from "../cropper/ImageEasyCropper";
import { convertBse64ToFormData } from "../../utilities/helpers";
import "remixicon/fonts/remixicon.css";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const useStyles = makeStyles((theme) => ({
  root1: {
    "&.MuiBox-root": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      border: 1,
      borderColor: theme.palette.common.border,
      borderStyle: "solid",
      borderRadius: 4,
      maxWidth: "100%",
    },
  },
  root2: {
    "&.MuiBox-root": {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      border: 1,
      borderColor: theme.palette.common.border,
      borderStyle: "solid",
      borderRadius: 4,
    },
  },
  image: {
    objectFit: "cover",
    // maxHeight: 230,
    width: "100%",
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "right",
    paddingRight: theme.spacing(1),
  },
}));

export function ProfileUploader(props) {
  const { fileURL, onChangeURL, uploadingStatus, aspectRatio } = props;
  const classes = useStyles();
  const theme = useTheme();
  const imageFileRef = useRef(null);
  // const [imageFile, setImageFile] = useState(null);
  const [imageFileURL, setImageFileURL] = useState(fileURL);
  const [hasImage, setHasImage] = useState(false);
  const { showToast } = useToast();

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setHasImage(Boolean(fileURL));
  }, []);

  //cropper
  const [input, setInput] = useState(null);
  const handleCloseCropper = () => {
    setInput(null);
    imageFileRef.current.value = "";
  };
  const handleCropCompleted = (img) => {
    setInput(null);
    imageFileRef.current.value = "";
    setImageFileURL(img);
    setHasImage(true);

    if (img) {
      const bannerBlob = convertBse64ToFormData(img);
      const formData = new FormData();
      formData.append("file", bannerBlob, "filename.png");
      setIsUploading(true);
      api
        .post(urls.mediaUpload, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setIsUploading(false);
          setImageFileURL(res.data.file_url);
          onChangeURL(res.data.file_url);
          uploadingStatus(false);
        })
        .catch((err) => {
          setIsUploading(false);
          uploadingStatus(false);
          setHasImage(false);
          showToast("Image upload failed, Please upload again", "error");
        });
    }
  };

  const handleUploadClick = () => {
    imageFileRef.current.click();
  };

  const handleDeleteClick = () => {
    setHasImage(false);
    // setImageFile(null);
    setImageFileURL(null);
    onChangeURL("");
  };

  return (
    <Fragment>
      <Box className={classes.root1}>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <input
              id="file"
              type="file"
              ref={imageFileRef}
              style={{ display: "none" }}
              accept="image/*"
              // onChange={handleFileChange}
              onChange={(e) => {
                if (e.target.files[0]) {
                  setInput(URL.createObjectURL(e.target.files[0]));
                  imageFileRef.current.value = "";
                }
              }}
            />
            <Stack
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {isUploading && (
                <CircularProgress sx={{ position: "absolute" }} />
              )}
              <img
                alt="mag1"
                src={hasImage ? imageFileURL : profilePlaceholder}
                className={classes.image}
                onClick={handleUploadClick}
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} justifyContent={"center"} spacing={1}>
            <IconButton
              size="small"
              sx={{
                bgcolor: "black",
                ":hover": {
                  bgcolor: alpha("#0000", 0.7),
                },
                ":disabled": {
                  bgcolor: alpha("#0000", 0.3),
                },
              }}
              onClick={handleUploadClick}
              disabled={isUploading}
            >
              <FileUploadIcon color="white" />
            </IconButton>
            {hasImage && (
              <IconButton
                size="small"
                onClick={handleDeleteClick}
                sx={{
                  bgcolor: "black",
                  ":hover": {
                    bgcolor: alpha("#0000", 0.7),
                  },
                  ":disabled": {
                    bgcolor: alpha("#0000", 0.3),
                  },
                }}
                disabled={isUploading}
              >
                <DeleteIcon color="white" />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Box>
      {input !== null && (
        <ImageEasyCropper
          selectedImage={input}
          aspectRatio={aspectRatio}
          closeCropper={handleCloseCropper}
          cropCompleted={handleCropCompleted}
        />
      )}
    </Fragment>
  );
}

ProfileUploader.propTypes = {
  fileURL: PropTypes.string,
  onChangeURL: PropTypes.func,
  aspectRatio: PropTypes.any,
};

ProfileUploader.defaultProps = {
  aspectRatio: 1 / 1,
};
