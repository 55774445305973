import { TextField, Autocomplete, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function CategoryChipTextField(props) {
  const classes = useStyles();
  const { label, onChange, options, values, error, helperText } = props;

  return (
    <Autocomplete
      id="cat-chip-field"
      className={classes.root}
      multiple
      options={options}
      value={values}
      getOptionLabel={(option) => option?.descriptions[0]?.category_name}
      getOptionDisabled={() => values && values.length > 0}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.id}
            variant="filled"
            label={option?.descriptions[0]?.category_name}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={onChange}
    />
  );
}

CategoryChipTextField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  values: PropTypes.array,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
