import React, { useState, useEffect } from "react";
import {
  Modal,
  Grid,
  Typography,
  Stack,
  Box,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  NormalTextField,
  HeaderButton,
  ProfileUploader,
  DropdownTextField,
  AdvertisementTextField,
  CoverUploader,
  TextFieldWithDropDown,
} from "../../../components";
import validator from "validator";
import { useToast, useAuth } from "../../../contexts";
import { api, urls } from "../../../services";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    background: theme.palette.common.white,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },

  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },

  images: {
    maxHeight: 150,
  },
}));

export function AddAdvertisement(props) {
  const classes = useStyles();
  const { open, onClose, onSubmit, languages, isEdit, selAd } = props;
  const [formData, setFormData] = useState({
    advertisement_title: selAd?.title ?? "",
    advertisement_image: selAd?.image_url ?? "",
    advertisement_link: selAd?.link_url ?? "",
    language_id: selAd?.language_id ?? 2,
    errors: {
      advertisement_title: false,
      advertisement_image: false,
      advertisement_link: false,
    },
  });
  const [isUploading, setIsUploading] = useState(false);
  const { showToast } = useToast();
  const { logout } = useAuth();

  const handleCancelClick = () => {
    onClose();
  };

  const handleSaveClick = () => {
    if (!formData.advertisement_title) {
      setFormData((prev) => {
        return {
          ...prev,
          errors: { ...prev.errors, advertisement_title: true },
        };
      });
    }
    if (!formData.advertisement_image) {
      setFormData((prev) => {
        return {
          ...prev,
          errors: { ...prev.errors, advertisement_image: true },
        };
      });
    }
    if (!formData.advertisement_link) {
      setFormData((prev) => {
        return {
          ...prev,
          errors: { ...prev.errors, advertisement_link: true },
        };
      });
    }
    if (
      formData.advertisement_title &&
      formData.advertisement_image &&
      formData.advertisement_link
    ) {
      let params = {
        advertisement_title: formData.advertisement_title,
        advertisement_image: formData.advertisement_image,
        advertisement_link: formData.advertisement_link,
        language_id: formData.language_id,
      };
      if (isEdit) {
        api
          .post(urls.editAdvertisement + selAd.id, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              logout();
            } else {
              showToast(err.response?.data?.message, "error");
            }
          });
      } else {
        api
          .post(urls.createAdvertisement, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              logout();
            } else {
              showToast(err.response?.data?.message, "error");
            }
          });
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>
          {isEdit ? "Edit Advertisement" : "Add Advertisement"}
        </Typography>
        <Grid container spacing={4}>
          <Grid item sx={{ maxWidth: 250 }}>
            <CoverUploader
              className={classes.images}
              fileURL={formData.advertisement_image}
              onChangeURL={(url) =>
                setFormData((prev) => {
                  return {
                    ...prev,
                    advertisement_image: url,
                    errors: { ...prev.errors, advertisement_image: false },
                  };
                })
              }
              uploadingStatus={(status) => setIsUploading(status)}
              aspectRatio={2.5 / 3.3}
            />
            {formData.errors.advertisement_image && (
              <FormHelperText error sx={{ width: "100%", textAlign: "center" }}>
                Advertisement image is required
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs>
            <Stack spacing={3} justifyContent={"space-between"} height={"100%"}>
              <Stack spacing={3}>
                <TextFieldWithDropDown
                  label={"Advertisement Title"}
                  multiline={true}
                  textValue={formData.advertisement_title}
                  onTextChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        advertisement_title: e.target.value,
                        errors: { ...prev.errors, advertisement_title: false },
                      };
                    })
                  }
                  dropOptions={languages}
                  dropValue={languages.find(
                    (x) => x.id === formData.language_id
                  )}
                  dropKey={"short_code"}
                  onDropChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        language_id: e.target.value,
                        errors: { ...prev.errors, language_id: false },
                      };
                    })
                  }
                  textError={formData.errors.advertisement_title}
                  textHelperText={
                    formData.errors.advertisement_title &&
                    "Enter advertisement title"
                  }
                />
                <NormalTextField
                  label="Link"
                  error={formData.errors.advertisement_link}
                  helperText={
                    formData.errors.advertisement_link && "Enter link"
                  }
                  onChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        advertisement_link: e.target.value,
                        errors: { ...prev.errors, advertisement_link: false },
                      };
                    })
                  }
                  value={formData.advertisement_link}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderButton type="secondary" onClick={handleCancelClick}>
                  Cancel
                </HeaderButton>
                <HeaderButton
                  type="primary"
                  onClick={handleSaveClick}
                  disabled={isUploading}
                >
                  {isEdit ? "Update" : "Save"}
                </HeaderButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

AddAdvertisement.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  languages: PropTypes.array,
  isEdit: PropTypes.bool,
  selAd: PropTypes.object,
};
