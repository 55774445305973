import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function SearchTextField(props) {
  const classes = useStyles();
  const { placeholder, searchInput, onSearchChange } = props;

  return (
    <TextField
      id="search-field"
      className={classes.root}
      value={searchInput}
      placeholder={placeholder}
      onChange={onSearchChange}
      variant="outlined"
      InputProps={{ startAdornment: <Search sx={{ pr: 1 }} /> }}
      size="small"
    />
  );
}

SearchTextField.propTypes = {
  placeholder: PropTypes.string,
  searchInput: PropTypes.string,
  onSearchChange: PropTypes.func,
};
