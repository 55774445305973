import { useState, useEffect, useRef, Fragment } from "react";
import {
  Stack,
  Box,
  IconButton,
  Menu,
  CircularProgress,
  MenuItem,
  useTheme,
  alpha,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { coverPlaceholder, deleteBlack } from "../../assets";
import { UploadButton } from "../../components";
import { api, urls } from "../../services";
import { useToast } from "../../contexts";
import PropTypes from "prop-types";
import { ColorExtractor } from "react-color-extractor";
import { ImageEasyCropper } from "../cropper/ImageEasyCropper";
import { convertBse64ToFormData } from "../../utilities/helpers";
import "remixicon/fonts/remixicon.css";
import DeleteIcon from "@mui/icons-material/Delete";
import PaletteIcon from "@mui/icons-material/Palette";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const useStyles = makeStyles((theme) => ({
  root1: {
    "&.MuiBox-root": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      border: 1,
      borderColor: theme.palette.common.border,
      borderStyle: "solid",
      borderRadius: 4,
      maxWidth: 200,
      [theme.breakpoints.up("xs")]: {
        maxWidth: 100, // Apply when screen width is equal to or greater than 'xs' breakpoint
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: 160, // Apply when screen width is equal to or greater than 'sm' breakpoint
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 180, // Apply when screen width is equal to or greater than 'lg' breakpoint
      },
    },
  },
  root2: {
    "&.MuiBox-root": {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      border: 1,
      borderColor: theme.palette.common.border,
      borderStyle: "solid",
      borderRadius: 4,
    },
  },
  image: {
    objectFit: "cover",
    // maxHeight: 230,
    width: "100%",
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "right",
    paddingRight: theme.spacing(1),
  },
}));

export function CoverUploader(props) {
  const {
    fileURL,
    onChangeURL,
    showColorPicker,
    onChangeColor,
    color,
    uploadingStatus,
    aspectRatio,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const imageFileRef = useRef(null);
  // const [imageFile, setImageFile] = useState(null);
  const [imageFileURL, setImageFileURL] = useState(fileURL);
  const [hasImage, setHasImage] = useState(false);
  const { showToast } = useToast();
  const [colors, setColors] = useState([]);
  const [menuEl, setMenuEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selColor, setSelColor] = useState(color);

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setHasImage(Boolean(fileURL));
  }, []);

  //cropper
  const [input, setInput] = useState(null);
  const handleCloseCropper = () => {
    setInput(null);
    imageFileRef.current.value = "";
  };
  const handleCropCompleted = (img) => {
    setInput(null);
    imageFileRef.current.value = "";
    setImageFileURL(img);
    setHasImage(true);

    if (img) {
      const bannerBlob = convertBse64ToFormData(img);
      const formData = new FormData();
      formData.append("file", bannerBlob, "filename.png");
      setIsUploading(true);
      api
        .post(urls.mediaUpload, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setIsUploading(false);
          setImageFileURL(res.data.file_url);
          onChangeURL(res.data.file_url);
          uploadingStatus(false);
        })
        .catch((err) => {
          setIsUploading(false);
          uploadingStatus(false);
          setHasImage(false);
          showToast("Image upload failed, Please upload again", "error");
        });
    }
  };

  const handleUploadClick = () => {
    imageFileRef.current.click();
  };

  const handleDeleteClick = () => {
    setHasImage(false);
    // setImageFile(null);
    setImageFileURL(null);
    onChangeURL("");
  };

  const handleGetColors = (colors) => {
    setColors(colors);
    if (selColor == null || selColor === "") {
      setSelColor(colors[0]);
      onChangeColor(colors[0]);
    }
  };

  return (
    <Fragment>
      <Box className={classes.root1}>
        <Stack spacing={2}>
          {/* {hasImage && (
            <Box className={classes.iconContainer}>
              <IconButton size="small" onClick={handleDeleteClick}>
                <img alt="del" src={deleteBlack} />
              </IconButton>
              {showColorPicker && (
                <Fragment>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      if (colors.length > 0) {
                        setMenuOpen(true);
                        setMenuEl(e.currentTarget);
                      }
                    }}
                  >
                    <div style={{ color: selColor, fontSize: 20 }}>
                      <i className="ri-palette-fill"></i>
                    </div>
                  </IconButton>
                  <Menu
                    open={menuOpen}
                    anchorEl={menuEl}
                    PaperProps={{
                      sx: {
                        width: "100px", // Set the width to your desired value
                        maxHeight: "400px", // Set the max height if needed
                      },
                    }}
                  >
                    {colors.map((c) => (
                      <MenuItem
                        onClick={() => {
                          setSelColor(c);
                          onChangeColor(c);
                          setMenuOpen(false);
                        }}
                        sx={{
                          backgroundColor: c,
                          ":hover": {
                            backgroundColor: c,
                          },
                          display: "flex", // To align content vertically
                          alignItems: "center", // To center content vertically
                        }}
                      >
                        <Box height={25}></Box>
                      </MenuItem>
                    ))}
                  </Menu>
                </Fragment>
              )}
            </Box>
          )} */}
          <Stack spacing={2}>
            <input
              id="file"
              type="file"
              ref={imageFileRef}
              style={{ display: "none" }}
              accept="image/*"
              // onChange={handleFileChange}
              onChange={(e) => {
                if (e.target.files[0]) {
                  setInput(URL.createObjectURL(e.target.files[0]));
                  imageFileRef.current.value = "";
                }
              }}
            />
            <Stack
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {isUploading && (
                <CircularProgress sx={{ position: "absolute" }} />
              )}
              <ColorExtractor getColors={handleGetColors}>
                <img
                  alt="mag1"
                  src={hasImage ? imageFileURL : coverPlaceholder}
                  className={classes.image}
                  onClick={handleUploadClick}
                />
              </ColorExtractor>
            </Stack>

            {/* <UploadButton onClick={handleUploadClick} disabled={isUploading}>
              {hasImage ? "Change Image" : "Upload Image"}
            </UploadButton> */}
          </Stack>
          <Stack direction={"row"} justifyContent={"center"} spacing={1}>
            <IconButton
              size="small"
              sx={{
                bgcolor: "black",
                ":hover": {
                  bgcolor: alpha("#0000", 0.7),
                },
              }}
              onClick={handleUploadClick}
              disabled={isUploading}
            >
              <FileUploadIcon color="white" />
            </IconButton>
            {showColorPicker && hasImage && (
              <IconButton
                size="small"
                onClick={handleDeleteClick}
                sx={{
                  bgcolor: "black",
                  ":hover": {
                    bgcolor: alpha("#0000", 0.7),
                  },
                }}
              >
                <DeleteIcon color="white" />
              </IconButton>
            )}
            {showColorPicker && hasImage && (
              <IconButton
                size="small"
                sx={{
                  bgcolor: selColor !== "" ? selColor : "#0f0f0f",
                  ":hover": {
                    bgcolor: alpha(selColor !== "" ? selColor : "#0f0f0f", 0.7),
                  },
                }}
                onClick={(e) => {
                  if (colors.length > 0) {
                    setMenuOpen(true);
                    setMenuEl(e.currentTarget);
                  }
                }}
              >
                <PaletteIcon color="white" />
              </IconButton>
            )}
            {showColorPicker && hasImage && (
              <Menu
                open={menuOpen}
                anchorEl={menuEl}
                PaperProps={{
                  sx: {
                    width: "100px", // Set the width to your desired value
                    maxHeight: "400px", // Set the max height if needed
                  },
                }}
              >
                {colors.map((c) => (
                  <MenuItem
                    onClick={() => {
                      setSelColor(c);
                      onChangeColor(c);
                      setMenuOpen(false);
                    }}
                    sx={{
                      backgroundColor: c,
                      ":hover": {
                        backgroundColor: c,
                      },
                      display: "flex", // To align content vertically
                      alignItems: "center", // To center content vertically
                    }}
                  >
                    <Box height={25}></Box>
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Stack>
        </Stack>
      </Box>
      {input !== null && (
        <ImageEasyCropper
          selectedImage={input}
          aspectRatio={aspectRatio}
          closeCropper={handleCloseCropper}
          cropCompleted={handleCropCompleted}
        />
      )}
    </Fragment>
  );
}

CoverUploader.propTypes = {
  fileURL: PropTypes.string,
  onChangeURL: PropTypes.func,
  showColorPicker: PropTypes.bool,
  onChangeColor: PropTypes.func,
  color: PropTypes.string,
  aspectRatio: PropTypes.any,
};

CoverUploader.defaultProps = {
  aspectRatio: 1 / 1,
};
