import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { magazine1, article1, author1 } from "../../assets";

const useStyles = makeStyles((theme) => ({
  profileImage: {
    width: 45,
    height: 45,
    borderRadius: 4,
    objectFit: "cover",
  },
  nameLabel: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
    },
  },
}));

export function AuthorTableItem(props) {
  const classes = useStyles();
  const {noImage, authimg,authname, articlecount} = props;
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        {noImage === true ? null : (
          <img alt="auth" src={authimg} className={classes.profileImage} />
        )}
        {/* <img alt="auth" src={author1} className={classes.profileImage} /> */}
        <Stack>
          <Typography className={classes.nameLabel}>{authname}</Typography>
          <Typography className={classes.articleLabel}>{articlecount}</Typography>
          {/* <Typography className={classes.nameLabel}>Authors</Typography>
          <Typography className={classes.articleLabel}>Articles</Typography> */}
        </Stack>
      </Stack>
    </Box>
  );
}
