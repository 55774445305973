import React from "react";
import { Modal, Grid, Typography, Stack, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  NormalTextField,
  HeaderButton,
  ProfileUploader,
  DropdownTextField,
} from "../../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    background: theme.palette.common.white,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
}));

export function AddLanguage(props) {
  const classes = useStyles();
  const { open, onClose } = props;

  const handleCancelClick = () => {
    onClose();
  };

  const handleSaveClick = () => {};

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>Add New Language</Typography>
        <Grid container spacing={4} direction="column">
        
          <Grid item xs>
            <Stack spacing={4}>
               <NormalTextField label="Name" />
               <NormalTextField label="Language Code" />
              <Stack direction="row" spacing={2}>
                <HeaderButton type="secondary" onClick={handleCancelClick}>
                  Cancel
                </HeaderButton>
                <HeaderButton type="primary" onClick={handleSaveClick}>
                Add
                </HeaderButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
