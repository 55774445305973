import { Typography, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(2),
    height: 25,
    display: "flex",
    alignItems: "center",
    "&.MuiTypography-root": {
      fontSize: 12,
      fontWeight: 600,
    },
  },
}));

export function CategoryLabel(props) {
  const classes = useStyles();
  const { children, color } = props;

  return (
    <Typography
      className={classes.root}
      sx={{ color: color, bgcolor: alpha(color, 0.2) }}
    >
      {children}
    </Typography>
  );
}

CategoryLabel.propTypes = {
  color: PropTypes.string,
};
