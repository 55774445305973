import { TextField, Autocomplete, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function ChipTextField(props) {
  const classes = useStyles();
  const { label, onChange, options, values, error, helperText } = props;

  return (
    <Autocomplete
      id="chip-field"
      className={classes.root}
      multiple
      options={options}
      value={values}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="filled" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={onChange}
    />
  );
}

ChipTextField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  values: PropTypes.array,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
