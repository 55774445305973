import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import {
  ListingHeader,
  ContainerCard,
  TableMenuButton,
} from "../../components";
import { makeStyles } from "@mui/styles";
import { AddCategory } from "../popups/AddCategory";
import dotted from "../../assets/images/dottedmore.svg";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import { CategoryTableItem } from "../../components/items/CategoryTableItem";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { debounce } from "lodash";
import NoDataCard from "../../components/cards/NoDataCard";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
}));

const menuOptions = [{ id: 2, label: "Delete" }];

export function Categories() {
  const classes = useStyles();
  const [openAddCategory, setOpenCategory] = useState(false);
  const [category, setCategory] = useState([]);
  const { showToast } = useToast();
  const { logout } = useAuth();
  const [languages, setLanguages] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPages] = useState(0);

  const [searchInput, setSearchInput] = useState("");
  const [confirmDelCat, setConfirmDelCat] = useState(false);
  const [selCat, setSelCat] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseAddCategory = () => {
    setOpenCategory(false);
  };
  const handleOnClickCategory = () => {
    setOpenCategory(true);
  };

  const getArticleCategory = () => {
    setIsLoading(true);
    api
      .get(urls.categories, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          filter_key: "",
          search_key: searchInput,
        },
      })
      .then((res) => {
        setCategory(res.data.data.article_category_list);
        setTotalPages(res.data.data.pagination.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getLanguages = () => {
    api
      .get(urls.languages)
      .then((res) => setLanguages(res.data.data))
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const onConfirmDeletion = () => {
    api
      .delete(urls.deleteCategory + selCat.id)
      .then((res) => {
        showToast("Category deleted successfully", "success");
        setPage(0);
        handleCloseEditCat();
        getArticleCategory();
      })
      .catch((err) => {
        setSelCat(null);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getLanguages();
    getArticleCategory();
  }, [page, rowsPerPage, searchInput]);

  const delayedSearch = useCallback(
    debounce((text) => {
      setSearchInput(text);
    }, 500),
    []
  );

  const handleOnSearch = (event) => {
    delayedSearch(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getArticleCategory();
  };

  const handleReloadCategory = () => {
    handleCloseAddCategory();
    getArticleCategory();
  };

  const handleMenuClick = (item) => {
    setConfirmDelCat(true);
    setSelCat(item);
  };

  const handleCloseEditCat = () => {
    setConfirmDelCat(false);
    setSelCat(null);
  };

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ListingHeader
            title={totalPage > 0 ? `Categories (${totalPage})` : "Categories"}
            btnTitle="Add New Category"
            placeholder="Search Category"
            onClickPrimary={handleOnClickCategory}
            onSearchChange={handleOnSearch}
          />
        </Grid>
        {isLoading && (
          <Grid item>
            <LoaderCard />
          </Grid>
        )}
        {!isLoading && (
          <Grid item>
            <ContainerCard>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header}>Category</TableCell>
                      <TableCell align="center" className={classes.header}>
                        Articles
                      </TableCell>
                      <TableCell className={classes.header}>Language</TableCell>
                      <TableCell width={100} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {category?.map((post, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            {<CategoryTableItem categoryname={post.name} />}
                          </TableCell>
                          <TableCell align="center">
                            {post.articles_count}
                          </TableCell>
                          <TableCell>{post.language}</TableCell>
                          <TableCell>
                            {post.articles_count === 0 && (
                              <TableMenuButton
                                options={menuOptions}
                                onOptionClick={() => handleMenuClick(post)}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {category.length === 0 && !isLoading && (
                <NoDataCard title={"No Data Found"} />
              )}
              <TablePagination
                component="div"
                count={totalPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ContainerCard>
          </Grid>
        )}
      </Grid>
      {openAddCategory && (
        <AddCategory
          open={openAddCategory}
          onClose={handleCloseAddCategory}
          onSubmit={handleReloadCategory}
          languages={languages}
        />
      )}
      {confirmDelCat && (
        <ConfirmationDialog
          open={confirmDelCat}
          onClose={handleCloseEditCat}
          message="Are you sure you want to delete this Category?"
          onSubmit={onConfirmDeletion}
        />
      )}
    </Fragment>
  );
}
