import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Grid, Stack, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AuthCard } from "../../../components";
import { PasswordTextField, ActionButton } from "../../../components";
import { passwordValidations } from "../../../utilities";
import { CircleIcon } from "../../../assets";
import { api, urls } from "../../../services";
import { useToast } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiContainer-root": {
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  messageText: {
    display: "flex",
    justifyContent: "center",
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.common.greyTxt,
    },
  },
  validationText: {
    "&.MuiTypography-root": {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  validationContainer: {},
}));

export function CreatePassword() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [validations, setValidations] = useState(passwordValidations);
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { showToast } = useToast();

  const handleSaveClick = () => {
    setIsClicked(true);
    if (isValid) {
      const params = {
        email: location.state.email,
        otp: location.state.otp,
        password: password,
      };
      api
        .post(urls.resetPassword, params)
        .then((res) => {
          showToast(res.data.message, "success");
          navigate("/login", { replace: true });
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    } else {
      validatePassword(password, true);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsClicked(false);
  };

  const getValidationColor = (isValid) => {
    if (isValid === true) {
      return theme.palette.common.greenTxt;
    } else if (isValid === false) {
      return theme.palette.common.redTxt;
    } else {
      return theme.palette.common.lightGreyTxt;
    }
  };

  const validatePassword = (password, click) => {
    // Regular expressions to match each condition
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const specialCharacterRegex = /[^A-Za-z0-9]/;
    const lengthRegex = /.{8,}/;

    // Check if all conditions are met
    const containsUppercase = uppercaseRegex.test(password);
    const containsLowercase = lowercaseRegex.test(password);
    const containsDigit = digitRegex.test(password);
    const containsSpecialCharacter = specialCharacterRegex.test(password);
    const hasMinimumLength = lengthRegex.test(password);

    setValidations((prev) => {
      const curr = [...prev];
      if (click) {
        curr[0].isValid = hasMinimumLength;
        curr[1].isValid = containsUppercase;
        curr[2].isValid = containsLowercase;
        curr[3].isValid = containsDigit;
        curr[4].isValid = containsSpecialCharacter;
      } else {
        curr[0].isValid = hasMinimumLength ? hasMinimumLength : null;
        curr[1].isValid = containsUppercase ? containsUppercase : null;
        curr[2].isValid = containsLowercase ? containsLowercase : null;
        curr[3].isValid = containsDigit ? containsDigit : null;
        curr[4].isValid = containsSpecialCharacter
          ? containsSpecialCharacter
          : null;
      }
      return curr;
    });

    setIsValid(
      hasMinimumLength &&
        containsUppercase &&
        containsLowercase &&
        containsDigit &&
        containsSpecialCharacter
    );
  };

  useEffect(() => {
    validatePassword(password, false);
  }, [password]);

  return (
    <Container maxWidth="sm" className={classes.root}>
      <AuthCard title="Set New Password" success={true}>
        <Grid container spacing={3} direction="column">
          <Grid item xs>
            <PasswordTextField
              label="New Password"
              onChange={handlePasswordChange}
              error={isClicked && !isValid}
              helperText={isClicked && !isValid ? "Enter valid password" : null}
            />
          </Grid>
          <Grid item xs>
            <Stack spacing={1}>
              {validations.map((val, i) => {
                return (
                  <Stack
                    key={i}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <CircleIcon
                      size={10}
                      color={getValidationColor(val.isValid)}
                    />
                    <Typography
                      className={classes.validationText}
                      color={getValidationColor(val.isValid)}
                    >
                      {val.title}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs>
            <ActionButton onClick={handleSaveClick}>Save</ActionButton>
          </Grid>
        </Grid>
      </AuthCard>
    </Container>
  );
}
