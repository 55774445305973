import { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  SideMenu,
  Dashboard,
  Magazines,
  MagazineDetails,
  Articles,
  Authors,
  Categories,
  Users,
  Languages,
  Plans,
  Feedback,
} from "../../pages";
import { Grid } from "@mui/material";
import { Advertisements } from "../Advertisements";
import { MainRoute } from "../../routes";
import { useAuth } from "../../contexts";

export function Main() {
  const { isLoggedIn } = useAuth();

  return (
    <Fragment>
      <Grid container sx={{ padding: 2 }} spacing={2}>
        <Grid item minWidth={300} sx={{ display: { xs: "none", lg: "block" } }}>
          {isLoggedIn && <SideMenu />}
        </Grid>
        <Grid item xs>
          <Routes>
            <Route element={<MainRoute />}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/magazines" element={<Magazines />} />
              <Route path="/magazines/:magID" element={<MagazineDetails />} />
              <Route path="/articles" element={<Articles />} />
              <Route path="/authors" element={<Authors />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/languages" element={<Languages />} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/users" element={<Users />} />
              <Route path="/advertisements" element={<Advertisements />} />
              <Route path="/feedback" element={<Feedback />} />
            </Route>
          </Routes>
        </Grid>
      </Grid>
    </Fragment>
  );
}
