import { Box, Stack, TableCell, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { magazine1, article1 } from "../../assets";
import dotted from "../../assets/images/dottedmore.svg";

const useStyles = makeStyles((theme) => ({
  coverImage: {
    width: 45,
    height: 55,
    borderRadius: 4,
    objectFit: "cover",
  },
  bookLabel: {
    "&.MuiTypography-root": {
      color: theme.palette.common.redTxt,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  nameLabel: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
}));

export function LanguageTableItem(props) {
  const classes = useStyles();
  const {noImage, language, code} = props;
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        
        <Stack>
          <Typography className={classes.nameLabel}>{language}</Typography>
          <Typography className={classes.nameLabel}>{code}</Typography>
      
       </Stack>
        </Stack>
     
    </Box>
  );
}
