import { Button, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloudUpload } from "@mui/icons-material";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "&.MuiButton-root": {
      fontSize: 16,
      [theme.breakpoints.up("xs")]: {
        fontSize: 12, // Apply when screen width is equal to or greater than 'xs' breakpoint
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14, // Apply when screen width is equal to or greater than 'sm' breakpoint
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: 16, // Apply when screen width is equal to or greater than 'xl' breakpoint
      },
      fontWeight: 600,
      color: "white",
      textTransform: "none",
    },
  },
}));

export function UploadButton(props) {
  const { children, onClick, disabled, height } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      className={classes.root}
      onClick={onClick}
      startIcon={<CloudUpload />}
      sx={{
        height: height,
        backgroundColor: disabled ? "lightgray" : theme.palette.common.blueTxt,
        "&:hover": {
          background: theme.palette.common.blueTxt,
        },
      }}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

UploadButton.propTypes = {
  onClick: PropTypes.func,
  height: PropTypes.number,
  disabled: PropTypes.bool,
};

UploadButton.defaultProps = {
  height: 40,
  disabled: false,
};
