import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MagazineTableItem, StatusLabel } from "../../components";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({}));

export function BasicTable(props) {
  const { data, onItemClick } = props;

  return (
    <TableContainer sx={{ maxHeight: 280 }}>
      <Table>
        <TableBody>
          {data?.map((item, i) => {
            return (
              <TableRow
                key={i}
                sx={{ cursor: "pointer" }}
                hover
                onClick={() => onItemClick(item.id)}
              >
                <TableCell>
                  {
                    <MagazineTableItem
                      cover={item.cover_image}
                      book={`${item.book_number} - ${item.year_number}`}
                      name={item.issue_name}
                    />
                  }
                </TableCell>
                <TableCell>{item.product_set_name}</TableCell>
                <TableCell>{item?.articles_count}</TableCell>
                <TableCell width={100} align="center">
                  <StatusLabel type="draft">Draft</StatusLabel>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BasicTable.protoTypes = {
  data: PropTypes.array,
  onItemClick: PropTypes.func,
};
