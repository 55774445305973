import { Paper, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderButton, SearchTextField } from "../../components";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      borderRadius: 0,
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
  },
}));

export function FeedbackHeader(props) {
  const classes = useStyles();
  const {
    title,
    btnTitle,
    placeholder,
    searchInput,
    onSearchChange,
    onClickPrimary,
  } = props;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography className={classes.titleText}>{title}</Typography>
        </Grid>

        <Grid item xs />
        <Grid item>
          <SearchTextField
            placeholder={placeholder}
            searchInput={searchInput}
            onSearchChange={onSearchChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

FeedbackHeader.propTypes = {
  title: PropTypes.string,
  btnTitle: PropTypes.string,
  placeholder: PropTypes.string,
  onClickPrimary: PropTypes.func,
  searchInput: PropTypes.string,
  onSearchChange: PropTypes.func,
};
