import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckCircle } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  draft: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.common.draftBg,
    color: "white",
    borderRadius: theme.spacing(2),
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  tickIcon: {
    color: theme.palette.common.greenTxt,
    width: 20,
    height: 20,
  },
}));

export function PublishedLabel(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <Stack direction="row" spacing={1}>
      <Typography>{children}</Typography>
      <CheckCircle className={classes.tickIcon} />
    </Stack>
  );
}
