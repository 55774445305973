import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  coverImage: {
    width: 45,
    height: 55,
    borderRadius: 4,
    objectFit: "cover",
  },
  bookLabel: {
    "&.MuiTypography-root": {
      color: theme.palette.common.redTxt,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  nameLabel: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
}));

export function ArticleTableItem(props) {
  const classes = useStyles();
  const { title, image } = props;

  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        <img alt="art" src={image} className={classes.coverImage} />
        <Stack>
          <Typography className={classes.nameLabel}>{title}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

ArticleTableItem.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
};
