import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Add } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiButton-root": {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontSize: 16,
      fontWeight: 600,
      color: "white",
      background: "black",
      "&:hover": {
        background: "black",
      },
      height: 45,
      textTransform: "none",
      borderRadius: 8,
    },
  },
}));

export function BlockButton(props) {
  const classes = useStyles();
  const { children, onClick } = props;

  return (
    <Button className={classes.root} onClick={onClick} startIcon={<Add />}>
      {children}
    </Button>
  );
}

BlockButton.propTypes = {
  onClick: PropTypes.func,
};
