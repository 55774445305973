import { Paper, Grid, Typography, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderButton, SearchTextField, HeaderDropdownField } from "..";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      borderRadius: 0,
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
  },
}));

export function DropdownListingHeader(props) {
  const classes = useStyles();
  const {
    title,
    btnTitle,
    placeholder,
    onClickPrimary,
    filterOptions,
    selFilter,
    onFilterChange,
    searchInput,
    onSearchChange,
  } = props;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography className={classes.titleText}>{title}</Typography>
        </Grid>
        <Grid item>
          <HeaderButton type="primary" showIcon={true} onClick={onClickPrimary}>
            {btnTitle}
          </HeaderButton>
        </Grid>
        <Grid item xs />
        <Stack direction="row" spacing={2} sx={{ paddingTop: "16px" }}>
          <Grid item>
            <HeaderDropdownField
              options={filterOptions}
              values={selFilter}
              noClearIcon={true}
              optionKey={"label"}
              onChange={onFilterChange}
            />
          </Grid>
          <Grid item>
            <SearchTextField
              placeholder={placeholder}
              searchInput={searchInput}
              onSearchChange={onSearchChange}
            />
          </Grid>
        </Stack>
      </Grid>
    </Paper>
  );
}

DropdownListingHeader.propTypes = {
  title: PropTypes.string,
  btnTitle: PropTypes.string,
  placeholder: PropTypes.string,
  onClickPrimary: PropTypes.func,
  filterOptions: PropTypes.array,
  selFilter: PropTypes.object,
  onFilterChange: PropTypes.object,
  searchInput: PropTypes.string,
  onSearchChange: PropTypes.func,
};
