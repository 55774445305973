import React from "react";
import {
  Modal,
  Grid,
  Typography,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { blockMenu } from "../../../utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    background: theme.palette.common.greyBg,
    boxShadow: 24,
    padding: theme.spacing(3),
    borderRadius: 6,
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(2),
  },
  listItem: {
    "&.MuiListItemButton-root": {
      border: 1,
      borderStyle: "solid",
      borderColor: theme.palette.common.border,
      marginBottom: theme.spacing(1),
    },
  },
  itemText: {
    "&.MuiListItemText-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
}));

export function SelectBlock(props) {
  const classes = useStyles();
  const { open, onClose, onSelect } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>Add Block</Typography>
        <List>
          {blockMenu.map((m, i) => {
            return (
              <ListItemButton
                className={classes.listItem}
                key={i}
                onClick={() => onSelect(m)}
                disabled={m.isSingle}
              >
                <ListItemText className={classes.itemText} disableTypography>
                  {m.type_name}
                </ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Modal>
  );
}
