
import { makeStyles } from "@mui/styles";
import copyicon from "../../assets/images/copyicon.svg";
import { Box } from "@mui/material";




const useStyles = makeStyles((theme) => ({
    box: {
     
      width:"20px",
      height:"20px"
    },
    border:{
      border:"1px solid #F1F1F1",
    
    
    }
  }));

export function CopyArticlebutton(props){
const classes = useStyles();
    return (
       <Box  className={classes.box}>
        <img  className={classes.border} src={copyicon}/>
       </Box>
      );
}
 
