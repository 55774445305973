import React, { Fragment } from "react";
import {
  Modal,
  Grid,
  Typography,
  Box,
  List,
  ListItemButton,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderButton, MagazineTableItem } from "../../../components";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    background: theme.palette.common.greyBg,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
}));

export function SelectMagazine(props) {
  const classes = useStyles();
  const { open, onClose, onSelect, magazines } = props;

  const handleCancelClick = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>Select Magazine</Typography>
        <Grid container spacing={2} direction="column">
          <Grid item xs>
            <List sx={{ overflow: "auto", maxHeight: 450 }}>
              {magazines.map((mag, i) => (
                <Fragment>
                  <ListItemButton key={i} onClick={() => onSelect(mag)}>
                    <MagazineTableItem
                      cover={mag.cover_image}
                      book={`${mag.book_number} - ${mag.year_number}`}
                      name={mag.issue_name}
                    />
                  </ListItemButton>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: 300 }}>
              <HeaderButton type="secondary" onClick={handleCancelClick}>
                Cancel
              </HeaderButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

SelectMagazine.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  magazines: PropTypes.array,
};
