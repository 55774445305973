import { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EyeIcon, EyeOffIcon } from "../../assets";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function PasswordTextField(props) {
  const classes = useStyles();
  const { label, onChange, value, error, helperText } = props;
  const [showPass, setShowPass] = useState(false);

  return (
    <TextField
      id="password-field"
      variant="outlined"
      className={classes.root}
      label={label}
      onChange={onChange}
      type={showPass ? "text" : "password"}
      value={value}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setShowPass((prev) => {
                  if (prev) {
                    return false;
                  } else {
                    return true;
                  }
                });
              }}
            >
              {showPass ? (
                <EyeIcon color="#000000" />
              ) : (
                <EyeOffIcon color="#000000" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

PasswordTextField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
