import React, { useState } from "react";
import {
  Modal,
  Grid,
  Typography,
  Stack,
  FormHelperText,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  NormalTextField,
  HeaderButton,
  ProfileUploader,
} from "../../../components";
import PropTypes from "prop-types";
import { api, urls } from "../../../services";
import { useToast } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    background: theme.palette.common.white,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
}));

export function AddAuthor(props) {
  const classes = useStyles();
  const { open, onClose, onSubmit, isEdit, selAuthor } = props;
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    auth_name: isEdit ? selAuthor.author_name : "",
    auth_image: isEdit ? selAuthor.author_image : "",
    errors: { auth_name: false, auth_image: false },
  });
  const [isApiCalling, setIsApiCalling] = useState(false);

  const handleImageChange = (url) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr.auth_image = url;
      curr.errors.auth_image = false;
      return curr;
    });
  };

  const handleNameChange = (e) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr.auth_name = e.target.value;
      curr.errors.auth_name = false;
      return curr;
    });
  };

  const handleCancelClick = () => {
    onClose();
  };

  const handleSaveClick = () => {
    let error = false;
    if (!formData.auth_name.trim()) {
      setFormData((prev) => {
        let curr = { ...prev };
        curr.errors.auth_name = true;
        error = true;
        return curr;
      });
    }
    if (!formData.auth_image.trim()) {
      setFormData((prev) => {
        let curr = { ...prev };
        curr.errors.auth_image = true;
        error = true;
        return curr;
      });
    }
    if (!error) {
      if (isEdit) {
        let params = {
          author_id: selAuthor.id,
          author_image: formData.auth_image,
          author_name: formData.auth_name,
        };
        api
          .post(urls.editAuthor, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
          })
          .catch((err) => {
            showToast(err.response?.data?.message, "error");
          });
      } else {
        let params = {
          profile_image: formData.auth_image,
          full_name: formData.auth_name,
        };
        api
          .post(urls.createAuthor, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
          })
          .catch((err) => {
            showToast("Author creation failed", "error");
          });
      }
    }
  };

  // useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>
          {isEdit ? "Edit Author" : "Add Author"}
        </Typography>
        <Grid container spacing={4} direction="column">
          <Grid item justifyContent={"center"} display={"flex"}>
            <Box width={"200px"}>
              <ProfileUploader
                fileURL={formData.auth_image}
                onChangeURL={handleImageChange}
                uploadingStatus={setIsApiCalling}
              />
              {formData.errors.auth_image && (
                <FormHelperText
                  error
                  sx={{ width: "100%", textAlign: "center" }}
                >
                  Author image is required
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs>
            <Stack spacing={4}>
              <NormalTextField
                label="Author Name"
                onChange={handleNameChange}
                error={formData.errors.auth_name}
                helperText={
                  formData.errors.auth_name && "Please enter author name"
                }
                value={formData.auth_name}
              />
              <Stack direction="row" spacing={2}>
                <HeaderButton type="secondary" onClick={handleCancelClick}>
                  Cancel
                </HeaderButton>
                <HeaderButton
                  type="primary"
                  onClick={handleSaveClick}
                  disabled={isApiCalling}
                >
                  Save
                </HeaderButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

AddAuthor.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  selAuthor: PropTypes.object,
};
