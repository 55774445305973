import { TextField, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function PriceTextField(props) {
  const classes = useStyles();
  const { label, onChange, value, error, helperText } = props;

  return (
    <TextField
      id="price-field"
      className={classes.root}
      label={label}
      onChange={onChange}
      type="number"
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
      }}
      onKeyDown={(e) => (e.key === "e" || e.key === "E") && e.preventDefault()}
      value={value}
      error={error}
      helperText={helperText}
    />
  );
}

PriceTextField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
