import { Stack, Box } from "@mui/material";
import { NormalTextField, SelectTextField } from "../../components";
import PropTypes from "prop-types";

export function TextFieldWithDropDown(props) {
  const {
    label,
    multiline,
    textValue,
    onTextChange,
    dropOptions,
    dropValue,
    dropKey,
    onDropChange,
    textError,
    textHelperText,
  } = props;

  return (
    <Stack direction="row" spacing={2}>
      <NormalTextField
        label={label}
        multiline={multiline}
        onChange={onTextChange}
        value={textValue}
        error={textError}
        helperText={textHelperText}
      />
      <Box sx={{ width: 70 }}>
        <SelectTextField
          options={dropOptions}
          value={dropValue}
          optionKey={dropKey}
          onChange={onDropChange}
        />
      </Box>
    </Stack>
  );
}

TextFieldWithDropDown.propTypes = {
  label: PropTypes.string,
  multiline: PropTypes.bool,
  textValue: PropTypes.string,
  onTextChange: PropTypes.func,
  dropOptions: PropTypes.array,
  dropValue: PropTypes.object,
  dropKey: PropTypes.string,
  onDropChange: PropTypes.func,
  textError: PropTypes.bool,
  textHelperText: PropTypes.string,
};
