import { Paper, Grid, Typography, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderButton, StatusLabel, MenuButton } from "../../components";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      borderRadius: 0,
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
  },
  divider: {
    "&.MuiDivider-root": {
      height: 45,
      width: 1,
      background: theme.palette.common.border,
    },
  },
}));

export function MagazineDetailsHeader(props) {
  const classes = useStyles();
  const { title, onClickPrimary, isDraft, onMenuOptionClick, disableBtn } =
    props;

  const menuOptions = [
    { id: 1, label: "Edit" },
    { id: 2, label: "Delete" },
  ];

  const options = isDraft ? menuOptions : [menuOptions[0]];

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography className={classes.titleText}>{title}</Typography>
        </Grid>
        {isDraft && (
          <Grid item>
            <StatusLabel type="draft">Draft</StatusLabel>
          </Grid>
        )}
        <Grid item xs />
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item>
          <MenuButton options={options} onOptionClick={onMenuOptionClick} />
        </Grid>
        {isDraft && (
          <Grid item sx={{ width: 200 }}>
            <HeaderButton
              type="primary"
              showIcon={false}
              onClick={onClickPrimary}
              disabled={disableBtn}
            >
              PUBLISH
            </HeaderButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

MagazineDetailsHeader.propTypes = {
  title: PropTypes.string,
  onClickPrimary: PropTypes.func,
  isDraft: PropTypes.bool,
  onMenuOptionClick: PropTypes.func,
  disableBtn: PropTypes.bool,
};
