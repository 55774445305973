import React, { Fragment, useState, useEffect, useCallback } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { KeyboardArrowUp } from "@mui/icons-material";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Tabs,
  Tab,
  Divider,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  Box,
  Collapse,
} from "@mui/material";
import { ListingHeader, ContainerCard } from "../../components";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddUsers } from "../popups/AddUsers";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import { debounce, endsWith } from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import NoDataCard from "../../components/cards/NoDataCard";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  tab: {
    "&.MuiTab-root": {
      textTransform: "none",
      fontSize: 20,
      fontWeight: 600,
      "&.Mui-selected": {
        color: theme.palette.common.redBtn,
      },
    },
  },
}));

export function Users() {
  const classes = useStyles();
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();

  const [tabValue, setTabValue] = useState(0);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openAddusers, setOpenUsers] = useState(false);
  const [rowOpen, setRowOpen] = useState(null);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalPage, setTotalPages] = useState(0);

  const [totalCount, setTotalCount] = useState(0);
  const [subscribedCount, setSubscribedCount] = useState(0);
  const [unsubscribedCount, setUnsubscribedCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const delayedSearch = useCallback(
    debounce((text) => {
      setSearchInput(text);
    }, 500),
    []
  );

  const handleOnSearch = (event) => {
    delayedSearch(event.target.value);
    setPage(0);
  };

  const handleTabChange = (e, v) => {
    setTabValue(v);
    setPage(0);
    // setTotalPages(0);
    setRowOpen(null);
  };

  const handleCloseAddUsers = () => {
    setOpenUsers(false);
  };

  const userAdded = () => {
    setOpenUsers(false);
    setPage(0);
    getUsers();
  };

  const handleOnClickUsers = () => {
    setOpenUsers(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowOpen(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setRowOpen(null);
  };

  const getUsers = () => {
    setIsLoading(true);
    api
      .get(urls.users, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          is_subscribed: tabValue === 0 ? true : false,
          search_key: searchInput,
        },
      })
      .then((res) => {
        setUsers(res.data.data.users);
        setTotalPages(res.data.data.pagination.total);
        setTotalCount(res.data.data.total_users_count);
        setSubscribedCount(res.data.data.subscribed_users_count);
        setUnsubscribedCount(res.data.data.unsubscribed_users_count);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getUsers();
  }, [page, rowsPerPage, tabValue, searchInput]);

  const handleRowToggle = (row) => {
    console.log(row);
    if (rowOpen !== row) {
      setRowOpen(row);
    } else {
      setRowOpen(null);
    }
  };

  const getDuration = (end_date) => {
    let endString = `${end_date} 23:59:59`;
    let end = moment(endString, "YYYY-MM-DD hh:mm:ss");
    let result = moment.duration(end.diff(moment.now())).asDays();
    return `${result.toFixed(0)}`;
  };

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ListingHeader
            title={totalCount > 0 ? `Users (${totalCount})` : "Users"}
            btnTitle="Add New User"
            placeholder="Search User"
            onClickPrimary={handleOnClickUsers}
            onSearchChange={handleOnSearch}
          />
        </Grid>
        {isLoading && (
          <Grid item>
            <LoaderCard />
          </Grid>
        )}
        {!isLoading && (
          <Grid item>
            <ContainerCard>
              <Stack>
                {searchInput === "" && (
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                      sx: { backgroundColor: theme.palette.common.redBtn },
                    }}
                  >
                    <Tab
                      className={classes.tab}
                      label={
                        subscribedCount > 0
                          ? `Subscribed Users (${subscribedCount})`
                          : "Subscribed Users"
                      }
                    />
                    <Tab
                      className={classes.tab}
                      label={
                        unsubscribedCount > 0
                          ? `Unsubscribed Users (${unsubscribedCount})`
                          : "Unsubscribed Users"
                      }
                    />
                  </Tabs>
                )}
                {searchInput === "" && <Divider />}
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontSize: 16, fontWeight: 600 }}>
                          Name
                        </TableCell>
                        <TableCell />
                        <TableCell sx={{ fontSize: 16, fontWeight: 600 }}>
                          Phone Number
                        </TableCell>
                        <TableCell />
                        <TableCell sx={{ fontSize: 16, fontWeight: 600 }}>
                          Subscription
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((u, i) => (
                        <Fragment>
                          <TableRow
                            key={i}
                            sx={{ cursor: "pointer", width: "100%" }}
                            hover
                          >
                            <TableCell>{u.first_name}</TableCell>
                            <TableCell />
                            <TableCell>{`${u.phone_code} ${u.phone_number}`}</TableCell>
                            <TableCell />
                            {tabValue === 0 ? (
                              <TableCell>
                                {`${u.subscriptions?.length ?? 0} ${pluralize(
                                  "Active Plan",
                                  u.subscriptions?.length ?? 0
                                )}`}
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleRowToggle(i)}
                                  disabled={u.subscriptions?.length === 0}
                                >
                                  {rowOpen === i ? (
                                    <KeyboardArrowUp />
                                  ) : (
                                    <KeyboardArrowDown />
                                  )}
                                </IconButton>
                              </TableCell>
                            ) : (
                              <TableCell
                                sx={{ paddingTop: 3, paddingBottom: 3 }}
                              >
                                Not Subscribed Yet
                              </TableCell>
                            )}
                          </TableRow>
                          {tabValue === 0 && (
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={6}
                              >
                                <Collapse
                                  in={rowOpen === i}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box sx={{ marginLeft: 5, marginRight: 5 }}>
                                    <Table size="medium">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell />
                                          <TableCell
                                            sx={{
                                              fontSize: 16,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Start Date
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              fontSize: 16,
                                              fontWeight: 600,
                                            }}
                                          >
                                            End Date
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              fontSize: 16,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell align="right"> </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {u.subscriptions?.map((s, j) => (
                                          <TableRow>
                                            <TableCell>{j + 1}</TableCell>
                                            <TableCell>
                                              {moment(
                                                s.start_date,
                                                "YYYY-MM-DD"
                                              ).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                              {moment(
                                                s.end_date,
                                                "YYYY-MM-DD"
                                              ).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                              {s.amount ? `₹${s.amount}` : "--"}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                color:
                                                  getDuration(s.end_date) <=
                                                    30 && "red",
                                              }}
                                              align="left"
                                            >
                                              {`${getDuration(
                                                s.end_date
                                              )} ${pluralize(
                                                "Day",
                                                getDuration(s.end_date)
                                              )} Left`}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {users.length === 0 && !isLoading && (
                  <NoDataCard title={"No Data Found"} />
                )}
                <TablePagination
                  sx={{ mt: 2 }}
                  component="div"
                  count={totalPage}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[25, 50, 100]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Stack>
            </ContainerCard>
          </Grid>
        )}
      </Grid>
      {openAddusers && (
        <AddUsers
          open={openAddusers}
          onClose={handleCloseAddUsers}
          onAddedUser={userAdded}
        />
      )}
    </Fragment>
  );
}
