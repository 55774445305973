import { Paper, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderButton } from "../../components";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      borderRadius: 0,
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
  },
}));

export function DashboardHeader(props) {
  const classes = useStyles();
  const { onClickPrimary, onClickSecondary } = props;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography className={classes.titleText}>Dashboard</Typography>
        </Grid>
        <Grid item xs />
        <Grid item>
          <HeaderButton
            type="secondary"
            onClick={onClickSecondary}
            showIcon={true}
          >
            Add New Article
          </HeaderButton>
        </Grid>
        <Grid item>
          <HeaderButton type="primary" onClick={onClickPrimary} showIcon={true}>
            Create New Magazine
          </HeaderButton>
        </Grid>
      </Grid>
    </Paper>
  );
}

DashboardHeader.propTypes = {
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
};
