import React, { Fragment, useCallback, useState } from "react";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import {
  ListingHeader,
  ContainerCard,
  MagazineTableItem,
  StatusLabel,
  PublishedLabel,
  ArticleTableItem,
  AuthorTableItem,
} from "../../components";
import { makeStyles } from "@mui/styles";
import { AddLanguage } from "../popups/AddLanguage";
import { FeedbackHeader } from "../../components/headers/FeedbackHeader";
import dateup from "../../assets/images/Frame.svg";
import { Margin } from "@mui/icons-material";
import { FeedbackTableItem } from "../../components/items/FeedbackTableItem";
import { useEffect } from "react";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import moment from "moment";
import { debounce } from "lodash";
import NoDataCard from "../../components/cards/NoDataCard";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
}));

export function Feedback() {
  const classes = useStyles();
  const [openAddLanguage, setOpenLanguage] = useState(false);
  const [feedback, setFeedback] = useState([]);

  const { showToast } = useToast();
  const { logout } = useAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPages] = useState(0);

  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const delayedSearch = useCallback(
    debounce((text) => {
      setSearchInput(text);
    }, 500),
    []
  );

  const handleOnSearch = (event) => {
    delayedSearch(event.target.value);
    setPage(0);
  };

  const handleCloseAddLanguage = () => {
    setOpenLanguage(false);
  };
  const handleOnclickLanguage = () => {
    setOpenLanguage(true);
  };
  const getFeedback = () => {
    setIsLoading(true);
    api
      .get(urls.feedbacks, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          filter_key: "",
          search_key: searchInput,
        },
      })
      .then((res) => {
        setFeedback(res.data.data.feedback_list);
        setTotalPages(res.data.data.pagination.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getFeedback();
  }, [page, rowsPerPage, searchInput]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getFeedback();
  };

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <FeedbackHeader
            title={totalPage > 0 ? `Feedbacks (${totalPage})` : "Feedbacks"}
            placeholder="Search Feedbacks"
            onSearchChange={handleOnSearch}
          />
        </Grid>
        {isLoading && (
          <Grid item>
            <LoaderCard />
          </Grid>
        )}
        {!isLoading && (
          <Grid item>
            <ContainerCard>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header}>
                        Date
                        <img src={dateup} width="15px" height="15px" />
                      </TableCell>
                      <TableCell className={classes.header}>Name</TableCell>
                      <TableCell className={classes.header}>Feedback</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* <TableRow sx={{ cursor: "pointer" }} hover>
                  <TableCell>01/01/2024</TableCell>
                  <TableCell>Muhammed Hashim</TableCell>
                  <TableCell>It is a good mobile application and good ui</TableCell>
                  
                </TableRow>
                <TableRow sx={{ cursor: "pointer" }} hover>
                  <TableCell>05/01/2024</TableCell>
                  <TableCell>Muhammed Abdul Hameed</TableCell>
                  <TableCell>It is a good mobile application and good ui</TableCell>
                 
                </TableRow>
                <TableRow sx={{ cursor: "pointer" }} hover>
                  <TableCell>05/01/2024</TableCell>
                  <TableCell>Muhammed Hashim</TableCell>
                  <TableCell>It is a good mobile application and good ui</TableCell>
                  
                </TableRow> */}
                    {feedback?.map((post, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            {
                              <FeedbackTableItem
                                feedbackdate={moment(post.creation_date).format(
                                  "DD/MM/YYYY"
                                )}
                              />
                            }
                          </TableCell>
                          <TableCell> {post.first_name}</TableCell>
                          <TableCell>{post.feedback}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {feedback?.length === 0 && !isLoading && (
                <NoDataCard title={"No Data Found"} />
              )}
              <TablePagination
                component="div"
                count={totalPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ContainerCard>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}
