import React, { useState, useEffect } from "react";
import {
  Modal,
  Grid,
  Typography,
  Stack,
  Box,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  NormalTextField,
  HeaderButton,
  DropdownTextField,
  CoverUploader,
  PriceTextField,
  DateTextField,
} from "../../../components";
import dayjs from "dayjs";
import { api, urls } from "../../../services";
import { useToast } from "../../../contexts";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    background: theme.palette.common.greyBg,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
}));

export function CreateMagazine(props) {
  const classes = useStyles();
  const { open, onClose, onSubmit, isEdit, magData, released } = props;
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    product_set_id: "",
    issue_name: isEdit ? magData.issue_name : "",
    book_number: isEdit ? magData.book_number : "",
    year_number: isEdit ? magData.year_number : "",
    frequency: 2,
    frequency_type: "weekly",
    cover_image: isEdit ? magData.cover_image : "",
    colour: isEdit ? magData.colour : "",
    amount: isEdit ? magData.amount : "",
    start_date: isEdit ? magData.start_date : "",
    end_date: isEdit ? magData.end_date : "",
    errors: {
      book_number: false,
      year_number: false,
      cover_image: false,
      amount: false,
      start_date: false,
      end_date: false,
    },
  });
  const [productSet, setProductSet] = useState([]);
  const [selProdSet, setSelProdSet] = useState(null);
  const [isCoverUploading, setIsCoverUploading] = useState(false);

  const handleCancelClick = () => {
    onClose();
  };

  const handleCreateClick = () => {
    let keys = Object.keys({ ...formData }).filter(
      (x) => !formData[x] || formData[x] === ""
    );

    let errors = { ...formData.errors };
    keys.map((k) => {
      errors[k] = true;
    });

    setFormData((prev) => {
      let curr = { ...prev };
      curr.errors = errors;
      return curr;
    });

    if (keys.length == 0) {
      let params = { ...formData };
      delete params.errors;

      if (isEdit) {
        api
          .post(urls.editMagazine + magData.id, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
          })
          .catch((err) => {
            showToast(err.response?.data?.message, "error");
          });
      } else {
        api
          .post(urls.createMagazine, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
          })
          .catch((err) => {
            showToast(err.response?.data?.message, "error");
          });
      }
    }
  };

  const handleFieldChange = (k, v) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr[k] = v;
      curr.errors[k] = false;
      return curr;
    });
  };

  const handleDateChange = (k, v) => {
    let date = dayjs(v).format("YYYY-MM-DD");
    setFormData((prev) => {
      let curr = { ...prev };
      curr[k] = date;
      curr.errors[k] = false;
      return curr;
    });
  };

  const handleChangeImage = (url) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr.cover_image = url;
      curr.errors.cover_image = false;
      return curr;
    });
  };

  const handleChangeColor = (color) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr.colour = color;
      return curr;
    });
  };

  const coverUploadingStatus = (status) => {
    setIsCoverUploading(status);
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    api
      .get(urls.getProductSet)
      .then((res) => {
        setProductSet(res.data.data);
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });

    if (isEdit) {
      console.log(magData);
    }
  }, []);

  useEffect(() => {
    if (productSet.length > 0) {
      setFormData((prev) => {
        let curr = { ...prev };
        curr.product_set_id = productSet[0].id;
        if (!isEdit) {
          curr.amount = productSet[0].default_price.toString();
        }
        // curr.year_number = productSet[0].year_number;
        return curr;
      });
      setSelProdSet(productSet[0]);
    }
  }, [productSet]);

  useEffect(() => {
    if (formData.start_date && formData.end_date) {
      let start = dayjs(formData.start_date);
      let end = dayjs(formData.end_date);

      if (start.date && end.date) {
        if (
          start.format("YYYY") === end.format("YYYY") &&
          start.format("MMM") === end.format("MMM")
        ) {
          let issue = `${start.format("YYYY")} ${start
            .format("MMM")
            .toUpperCase()} ${start.format("DD")} - ${end.format("DD")}`;
          setFormData((prev) => {
            let curr = { ...prev };
            curr.issue_name = issue;
            return curr;
          });
        } else if (start.format("YYYY") === end.format("YYYY")) {
          let issue = `${start.format("YYYY")} ${start.format("DD")} ${start
            .format("MMM")
            .toUpperCase()} - ${end.format("DD")} ${end
            .format("MMM")
            .toUpperCase()}`;
          setFormData((prev) => {
            let curr = { ...prev };
            curr.issue_name = issue;
            return curr;
          });
        } else {
          let issue = `${start.format("DD")} ${start
            .format("MMM")
            .toUpperCase()} ${start.format("YYYY")} - ${end.format("DD")} ${end
            .format("MMM")
            .toUpperCase()} ${end.format("YYYY")}`;
          setFormData((prev) => {
            let curr = { ...prev };
            curr.issue_name = issue;
            return curr;
          });
        }
        // let year_number = start.format("YYYY") - 1981;
        setFormData((prev) => {
          let curr = { ...prev };
          // curr.year_number = year_number;
          return curr;
        });
      }
    }
  }, [formData.start_date, formData.end_date]);

  return (
    <Modal open={open}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>
          {isEdit ? "Edit Magazine" : "Create New Magazine"}
        </Typography>
        <Grid container spacing={4}>
          <Grid item sx={{ width: 250 }}>
            <CoverUploader
              onChangeURL={handleChangeImage}
              showColorPicker={true}
              onChangeColor={handleChangeColor}
              fileURL={formData.cover_image}
              color={formData.colour}
              uploadingStatus={coverUploadingStatus}
              aspectRatio={2.5 / 3.3}
            />
            {formData.errors.cover_image && (
              <FormHelperText error sx={{ width: "100%", textAlign: "center" }}>
                Cover image is mandatory
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs>
            <Stack spacing={2}>
              <DropdownTextField
                label="Magazine Category"
                options={productSet}
                values={selProdSet}
                optionKey={"name"}
                disabled={true}
              />
              <Stack direction="row" spacing={2}>
                <PriceTextField
                  label="Price"
                  value={formData.amount}
                  onChange={(e) => handleFieldChange("amount", e.target.value)}
                  error={formData.errors.amount}
                  helperText={formData.errors.amount && "Enter amount"}
                />
                <NormalTextField
                  label="Book Number"
                  value={formData.book_number}
                  type={"number"}
                  onChange={(e) =>
                    handleFieldChange("book_number", e.target.value)
                  }
                  // suffix={`- ${formData.year_number}`}
                  error={formData.errors.book_number}
                  helperText={
                    formData.errors.book_number && "Enter book number"
                  }
                  disabled={released}
                />
                <NormalTextField
                  label="Year Number"
                  value={formData.year_number}
                  type={"number"}
                  onChange={(e) =>
                    handleFieldChange("year_number", e.target.value)
                  }
                  error={formData.errors.year_number}
                  helperText={
                    formData.errors.year_number && "Enter year number"
                  }
                  disabled={released}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <DateTextField
                  label="Start Date"
                  onChange={(v) => handleDateChange("start_date", v)}
                  value={dayjs(formData.start_date)}
                  error={formData.errors.start_date}
                  helperText={formData.errors.start_date && "Enter start date"}
                  disabled={released}
                />
                <DateTextField
                  label="End Date"
                  onChange={(v) => handleDateChange("end_date", v)}
                  value={dayjs(formData.end_date)}
                  error={formData.errors.end_date}
                  helperText={formData.errors.end_date && "Enter end date"}
                  disabled={released}
                />
              </Stack>
              <NormalTextField
                label="Issue Name"
                value={formData.issue_name}
                disabled={true}
              />
              <Stack direction="row" spacing={2}>
                <HeaderButton type="secondary" onClick={handleCancelClick}>
                  Cancel
                </HeaderButton>
                <HeaderButton
                  type="primary"
                  onClick={handleCreateClick}
                  disabled={isCoverUploading}
                >
                  {isEdit ? "Update" : "Create"}
                </HeaderButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

CreateMagazine.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  magData: PropTypes.object,
  released: PropTypes.bool,
};

CreateMagazine.defaultProps = {
  released: false,
};
