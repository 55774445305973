import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { magazine1, article1 } from "../../assets";
import  advertiseimg from "../../assets/images/advertisement1.png";
const useStyles = makeStyles((theme) => ({
  coverImage: {
    width: 45,
    height: 55,
    borderRadius: 4,
    objectFit: "cover",
  },
  
  bookLabel: {
    "&.MuiTypography-root": {
      color: theme.palette.common.redTxt,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  nameLabel: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
}));

export function AdvertisementTableItem(props) {
  const classes = useStyles();
  const {noImage, advertisementname,advertiseimg, creadteddate} = props;

  return (
    <Box>
       <Stack direction="row" spacing={2} alignItems="center">
        {noImage === true ? null : (
          <img alt="auth" src={advertiseimg} className={classes.coverImage} />
        )}
        {/* <img alt="auth" src={author1} className={classes.profileImage} /> */}
        <Stack>
          <Typography className={classes.nameLabel}>{advertisementname}</Typography>
          <Typography className={classes.articleLabel}>{creadteddate}</Typography>
          {/* <Typography className={classes.nameLabel}>Authors</Typography>
          <Typography className={classes.articleLabel}>Articles</Typography> */}
        </Stack>
      </Stack>
    </Box>
  );
}
