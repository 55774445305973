import { Fragment } from "react";
import { Paper, Box, Typography, Divider, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { logo, tick } from "../../assets";
import PropTypes from "prop-types";
import { PlanValidityTextField } from "../fields/PlanValidityTextField";
import { DropdownTextField } from "../fields/DropdownTextField";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            minWidth: "90%",
        },
        minWidth: 420,
    },
    megazinePlanBox: {
        width: 300,
        border: "5px solid #ECECEC",
        padding: "20px",
        borderRadius: "4px"
    },
    titleBar: {
        height: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    logoImage: {
        width: 220,
        height: 40,
    },
    titleText: {
        display: "flex",
        justifyContent: "center",
        "&.MuiTypography-root": {
            fontSize: 24,
            fontWeight: 600,
            marginBottom: theme.spacing(4),
        },
    },
    divider: {
        "&.MuiDivider-root": {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(-4),
            marginRight: theme.spacing(-4),
        },
    },
    messageText: {
        display: "flex",
        justifyContent: "center",
        "&.MuiTypography-root": {
            fontSize: 16,
            fontWeight: 400,
        },
    },
    tickImage: {
        width: 60,
        height: 60,
    },
}));

export function MegazinePlanCard(props) {
    const classes = useStyles();
    const { title, success } = props;

    return (
<Fragment>
        <Box className={classes.megazinePlanBox} >
            <Stack spacing={4}>
                <DropdownTextField
                    label="Magazine Category"
                    options={[{ id: 1, label: "Malayalam" }, { id: 2, label: "English" }, { id: 3, label: "Arabic" }]}
                    values={{ id: 1, label: "Malayalam" }}
                    noClearIcon={true}
                    optionKey={"label"}

                />
                <PlanValidityTextField label=" Plan Validity" />
            </Stack>


        </Box>
</Fragment>
    );
}

MegazinePlanCard.propTypes = {
    title: PropTypes.string,
    success: PropTypes.bool,
};
