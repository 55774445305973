import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976D2", // Your primary color
    },
    secondary: {
      main: "#F57C00", // Your secondary color
    },
    tertiary: {
      main: "#388E3C", // Your tertiary color
    },
    switch: {
      main: "#00C058",
    },
    common: {
      black: "#000",
      white: "#fff",
      greyBg: "#F0F0F0",
      redBtn: "#E91F29",
      greyTxt: "#303030",
      greyBorder: "#D9D9D9",
      lightGreyTxt: "#656565",
      greenTxt: "#00C058",
      blackTxt: "#121212",
      redTxt: "#EB363F",
      blueTxt: "#1F7CE9",
      orangeTxt: "#FB8507",
      draftBg: "#469BFF",
      border: "#CCCCCC",
    },
    white: {
      main: "#fff",
    },
    // Other color definitions...
  },
  // Other theme settings...
});

export default theme;
