import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { magazine1, article1, author1 } from "../../assets";

const useStyles = makeStyles((theme) => ({
  profileImage: {
    width: 45,
    height: 45,
    borderRadius: 4,
    objectFit: "cover",
  },
  nameLabel: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
    },
  },
}));

export function CategoryTableItem(props) {
  const classes = useStyles();
  const { categoryname,articlecount, language} = props;
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
       
      
        <Stack>
          <Typography className={classes.nameLabel}>{categoryname}</Typography>
          <Typography className={classes.articleLabel}>{articlecount}</Typography>
          <Typography className={classes.articleLabel}>{language}</Typography>
          {/* <Typography className={classes.nameLabel}>Authors</Typography>
          <Typography className={classes.articleLabel}>Articles</Typography> */}
        </Stack>
      </Stack>
    </Box>
  );
}
