import { createContext, useContext, useState } from "react";
import { useLocalStorage } from "../hooks";

const AuthContext = createContext();

const AuthProvider = (props) => {
  const { getItem, removeItem } = useLocalStorage();
  const token = getItem("access_token");

  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(token));

  const login = () => {
    // Perform login logic
    setIsLoggedIn(true);
  };

  const logout = () => {
    // Perform logout logic
    setIsLoggedIn(false);
    removeItem("access_token");
    removeItem("refresh_token");
    removeItem("user_data");
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
