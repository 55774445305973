import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  useTheme,
} from "@mui/material";
import { HeaderButton } from "../buttons/HeaderButton";

export const ConfirmationDialog = (props) => {
  const { open, onClose, onSubmit, message } = props;
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: { p: 2 },
        elevation: 0,
      }}
    >
      <DialogTitle
        sx={{
          fontSize: 24,
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        Confirm Action
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ pb: 2, justifyContent: "center", gap: 2 }}>
        <HeaderButton type="secondary" onClick={onClose}>
          Cancel
        </HeaderButton>
        <HeaderButton type="primary" onClick={onSubmit}>
          Confirm
        </HeaderButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
