import { FormControl, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function SelectTextField(props) {
  const classes = useStyles();
  const { id, onChange, options, value, optionKey } = props;

  return (
    <FormControl>
      <Select
        id={id}
        className={classes.root}
        options={options}
        value={value}
        onChange={onChange}
      >
        {options.map((o) => {
          return (
            <MenuItem key={o.id} value={o}>
              {o[optionKey].toUpperCase()}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectTextField.propTypes = {
  id: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.object,
  optionKey: PropTypes.string,
};
