import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Grid, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AuthCard } from "../../../components";
import { ActionButton, RedLinkButton } from "../../../components";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import { api, urls } from "../../../services";
import { useToast } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiContainer-root": {
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  backContainer: {
    display: "flex",
    justifyContent: "center",
  },
  messageText: {
    display: "flex",
    justifyContent: "center",
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.common.greyTxt,
    },
  },
  emailText: {
    display: "flex",
    justifyContent: "center",
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.common.greyTxt,
      marginTop: theme.spacing(1),
    },
  },
  otpContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  otpField: {
    height: 20,
    padding: 20,
    fontSize: 26,
    fontWeight: 500,
    borderRadius: 4,
    border: "1px solid #D9D9D9",
  },
  questionText: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.common.lightGreyTxt,
    },
  },
  timerText: {
    "&.MuiTypography-root": {
      fontSize: 12,
      fontWeight: 500,
    },
  },
}));

export function VerifyEmail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const { showToast } = useToast();

  const handleConfirmClick = () => {
    if (otp.length === 4) {
      const params = { email: location.state.email, otp: otp };
      api
        .post(urls.verifyOTP, params)
        .then((res) => {
          showToast(res.data.message, "success");
          navigate("/create", {
            replace: true,
            state: { email: location.state.email, otp: otp },
          });
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    } else {
      showToast("Enter valid OTP", "error");
    }
  };

  const handleBackClick = () => {
    navigate("/login", { replace: true });
  };

  const countdownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <Box className={classes.otpContainer}>
          <Typography className={classes.timerText}>
            {minutes}:{seconds}
          </Typography>
        </Box>
      );
    }
  };

  const handleResendClick = () => {
    const params = { email: location.state.email };
    api
      .post(urls.sendOTP, params)
      .then((res) => {
        showToast(res.data.message, "success");
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <AuthCard title="OTP Verification">
        <Grid container spacing={3} direction="column">
          <Grid item xs>
            <Typography className={classes.messageText}>
              Enter the OTP you received to
            </Typography>
            <Typography className={classes.emailText}>
              {location.state.email}
            </Typography>
          </Grid>
          <Grid item xs className={classes.otpContainer}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span style={{ margin: 5 }} />}
              renderInput={(props) => <input {...props} />}
              inputStyle={classes.otpField}
              inputType="number"
              shouldAutoFocus
            />
          </Grid>
          <Grid item xs>
            <Grid container className={classes.otpContainer}>
              <Grid item>
                <Typography className={classes.questionText}>
                  Didn't receive code?
                </Typography>
              </Grid>
              <Grid item>
                <RedLinkButton onClick={handleResendClick}>
                  Resend Code
                </RedLinkButton>
              </Grid>
            </Grid>
            {/* <Countdown
              date={Date.now() + 120000}
              intervalDelay={0}
              renderer={countdownRenderer}
            /> */}
          </Grid>
          <Grid item xs>
            <ActionButton onClick={handleConfirmClick}>Confirm</ActionButton>
          </Grid>
          <Grid item xs className={classes.backContainer}>
            <RedLinkButton onClick={handleBackClick}>
              Back to Login
            </RedLinkButton>
          </Grid>
        </Grid>
      </AuthCard>
    </Container>
  );
}
