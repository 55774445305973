import {
  TextField,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 40,
  },
}));

export function HeaderDropdownField(props) {
  const classes = useStyles();
  const { id, label, onChange, options, values, noClearIcon, optionKey } =
    props;

  return (
    <FormControl sx={{ width: "140px" }}>
      <Select
        id={id}
        className={classes.root}
        options={options}
        value={values}
        onChange={onChange}
      >
        {options.map((o) => {
          return (
            <MenuItem key={o.id} value={o}>
              {o[optionKey]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

HeaderDropdownField.propTypes = {
  id: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  values: PropTypes.object,
  noClearIcon: PropTypes.bool,
  optionKey: PropTypes.string,
};
