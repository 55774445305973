import { useState, Fragment } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MoreHoriz } from "@mui/icons-material";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiIconButton-root": {
      border: 1,
      borderStyle: "solid",
      borderColor: theme.palette.common.border,
      borderRadius: 4,
      height: 45,
      width: 45,
      color: "black",
    },
  },
}));

export function TableMenuButton(props) {
  const classes = useStyles();
  const { onClick, options, onOptionClick, disabled } = props;
  const [menuEl, setMenuEl] = useState(null);

  const handleButtonClick = (e) => {
    setMenuEl(e.currentTarget);
  };

  return (
    <Fragment>
      <IconButton
        // className={classes.root}
        onClick={handleButtonClick}
        disabled={disabled}
      >
        <MoreHoriz sx={{ color: "black" }} />
      </IconButton>
      <Menu
        anchorEl={menuEl}
        open={Boolean(menuEl)}
        onClose={() => {
          setMenuEl(null);
        }}
      >
        {options.map((m, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              onOptionClick(m);
              setMenuEl(null);
            }}
          >
            {m.label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

TableMenuButton.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.array,
  onOptionClick: PropTypes.func,
  disabled: PropTypes.bool,
};
