import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { magazine1, article1, author1 } from "../../assets";

const useStyles = makeStyles((theme) => ({
  profileImage: {
    width: 45,
    height: 45,
    borderRadius: 4,
    objectFit: "cover",
  },
  nameLabel: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
    },
  },
}));

export function FeedbackTableItem(props) {
  const classes = useStyles();
  const { feedbackdate,feedbackname, feedback} = props;
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
       
      
        <Stack>
          <Typography className={classes.nameLabel}>{feedbackdate}</Typography>
          <Typography className={classes.articleLabel}>{feedbackname}</Typography>
          <Typography className={classes.articleLabel}>{feedback}</Typography>
  
        </Stack>
      </Stack>
    </Box>
  );
}
