import { TextField, InputAdornment, Autocomplete } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);

export function PhoneTextField(props) {
  const classes = useStyles();
  const {
    id,
    label,
    onChange,
    value,
    error,
    required,
    codeOptions,
    codeValue,
    onCodeChange,
  } = props;

  return (
    <TextField
    
      variant="outlined"
      type="number"
      className={classes.root}
      id={id}
      label={label}
      onChange={onChange}
      value={value}
      error={error}
      required={required}
      placeholder="Number"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Autocomplete
              options={codeOptions}
              value={codeValue}
              disableClearable
              getOptionLabel={(option) =>
                option ? `+${option.phone_code}` : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& fieldset": { border: "none" },
                    width: 120,
                    marginLeft: -2,
                  }}
                  placeholder="Code"
                />
              )}
              sx={{ paddingRight: 0 }}
              onChange={onCodeChange}
            />
          </InputAdornment>
        ),
      }}
      onKeyDown={(e) => (e.key === "e" || e.key === "E") && e.preventDefault()}
      // inputProps={{ maxLength: 10 }}
    />
  );
}
