import React, { Fragment, useState, useEffect } from "react";
import { Grid, Stack, useTheme } from "@mui/material";
import {
  DashboardHeader,
  CounterCard,
  NormalCard,
  BasicTable,
  MagazineDashboardItem,
} from "../../components";
import { CreateMagazine, SelectMagazine, CreateArticle } from "../../pages";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";
import { useNavigate } from "react-router-dom";

export function Dashboard(props) {
  const [openCreateMag, setOpenCreateMag] = useState(false);
  const [openSelectMag, setOpenSelectMag] = useState(false);
  const [openCreateArt, setOpenCreateArt] = useState(false);
  const [dashboardData, setDashbaordData] = useState(null);
  const [selMag, setSelMag] = useState(null);

  const { showToast } = useToast();
  const { logout } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const getDashboard = () => {
    api
      .get(urls.dashboard)
      .then((res) => setDashbaordData(res.data.data))
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleCloseCreateMag = () => {
    setOpenCreateMag(false);
  };

  const handleOnClickPrimary = () => {
    setOpenCreateMag(true);
  };

  const handleCloseSelectMag = () => {
    setOpenSelectMag(false);
  };

  const handleOnClickSecondary = () => {
    setOpenSelectMag(true);
  };

  const handleSelectMag = (mag) => {
    handleCloseSelectMag();
    setSelMag(mag);
    setOpenCreateArt(true);
  };

  const handleCloseCreateArt = (e, r) => {
    !r && setOpenCreateArt(false);
  };

  const handleReloadDashboard = () => {
    handleCloseCreateMag();
    handleCloseCreateArt();
    getDashboard();
  };

  const handleMagClick = (id) => {
    navigate(`/magazines/${id}`);
  };

  useEffect(() => {
    getDashboard();
  }, []);

  const handleViewAllDrafted = () => {
    navigate("/magazines", { state: { filter: "draft" } });
  };

  const handleViewAllPublished = () => {
    navigate("/magazines", { state: { filter: "published" } });
  };

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item xs>
          <DashboardHeader
            onClickPrimary={handleOnClickPrimary}
            onClickSecondary={handleOnClickSecondary}
          />
        </Grid>
        <Grid item xs>
          <Stack direction="row" spacing={2}>
            <CounterCard
              id={1}
              title="Published Magazines"
              icon="ri-book-open-line"
              color={theme.palette.common.redTxt}
              count={dashboardData?.published_magazines_count ?? "--"}
            />
            <CounterCard
              id={2}
              title="Published Articles"
              icon="ri-file-list-3-line"
              color={theme.palette.common.greenTxt}
              count={dashboardData?.published_articles_count ?? "--"}
            />
            <CounterCard
              id={3}
              title="No. of Authors"
              icon="ri-pen-nib-line"
              color={theme.palette.common.blueTxt}
              count={dashboardData?.authors_count ?? "--"}
            />
            <CounterCard
              id={4}
              title="No. of Subscribers"
              icon="ri-vip-crown-2-line"
              color={theme.palette.common.orangeTxt}
              count={dashboardData?.subscribed_users_count ?? "--"}
            />
          </Stack>
        </Grid>
        {dashboardData?.drafted_magazines?.length > 0 && (
          <Grid item xs>
            <NormalCard
              title="Drafted Magazines"
              showViewAll={true}
              noDivider={true}
              onViewAllClick={handleViewAllDrafted}
            >
              <BasicTable
                data={dashboardData?.drafted_magazines}
                onItemClick={handleMagClick}
              />
            </NormalCard>
          </Grid>
        )}
        <Grid item xs>
          <NormalCard
            title="Latest Published Magazines"
            showViewAll={true}
            noDivider={true}
            onViewAllClick={handleViewAllPublished}
          >
            <Grid
              container
              direction={"column"}
              sx={{
                pt: 1,
                overflowX: "auto",
                height: 370,
                gap: 4,
              }}
            >
              {dashboardData?.published_magazines?.map((post, i) => {
                return (
                  <Grid item key={i}>
                    <MagazineDashboardItem
                      cover={post.cover_image}
                      book={`${post.book_number} - ${post.year_number}`}
                      name={post.issue_name}
                      onItemClick={() => handleMagClick(post.id)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </NormalCard>
        </Grid>
      </Grid>
      {openCreateMag && (
        <CreateMagazine
          open={openCreateMag}
          onClose={handleCloseCreateMag}
          onSubmit={handleReloadDashboard}
        />
      )}
      {openSelectMag && (
        <SelectMagazine
          open={openSelectMag}
          onClose={handleCloseSelectMag}
          onSelect={handleSelectMag}
          magazines={dashboardData?.drafted_magazines}
        />
      )}
      {openCreateArt && (
        <CreateArticle
          open={openCreateArt}
          onClose={handleCloseCreateArt}
          magazine={selMag}
          onSubmit={handleReloadDashboard}
        />
      )}
    </Fragment>
  );
}
