import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AuthCard } from "../../../components";
import {
  EmailTextField,
  ActionButton,
  RedLinkButton,
} from "../../../components";
import { api, urls } from "../../../services";
import validator from "validator";
import { useToast } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiContainer-root": {
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  backContainer: {
    display: "flex",
    justifyContent: "center",
  },
  messageText: {
    display: "flex",
    justifyContent: "center",
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.common.greyTxt,
    },
  },
}));

export function ForgotPassword() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    errors: { email: false },
  });
  const { showToast } = useToast();

  const handleGetClick = () => {
    let invalid = false;
    if (!formData.email || !validator.isEmail(formData.email)) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.email = true;
        return curr;
      });
      invalid = true;
    }

    if (!invalid) {
      const params = { ...formData };
      delete params.errors;
      api
        .post(urls.sendOTP, params)
        .then((res) => {
          showToast(res.data.message, "success");
          navigate("/verify", {
            replace: true,
            state: { email: formData.email },
          });
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  const handleBackClick = () => {
    navigate("/login", { replace: true });
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <AuthCard title="Reset Password">
        <Grid container spacing={3} direction="column">
          <Grid item xs>
            <Typography className={classes.messageText}>
              OTP will be sent to this email
            </Typography>
          </Grid>
          <Grid item xs>
            <EmailTextField
              label="Email ID"
              value={formData.email}
              error={formData.errors.email}
              helperText={formData.errors.email ? "Enter valid email" : null}
              onChange={(e) => updateFormData("email", e.target.value)}
            />
          </Grid>
          <Grid item xs>
            <ActionButton onClick={handleGetClick}>Get OTP</ActionButton>
          </Grid>
          <Grid item xs className={classes.backContainer}>
            <RedLinkButton onClick={handleBackClick}>
              Back to Login
            </RedLinkButton>
          </Grid>
        </Grid>
      </AuthCard>
    </Container>
  );
}
