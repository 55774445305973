import React, { useState } from "react";
import { Modal, Grid, Typography, Stack, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  NormalTextField,
  HeaderButton,

} from "../../../components";
import { ItemButton } from "../../../components/buttons/ItemButton";
import { MegazinePlanCard } from "../../../components/cards/MegazinePlanCard";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    background: theme.palette.common.greyBg,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },
  megazinePlanBox: {
    width: 300,
    border: "5px solid #ECECEC",
    padding: "20px",
    borderRadius: "4px"
  },
  discountBox:{
   background: "#EB363F12",
   color: "#EB363F",
   width: 170,
   fontSize:"12px",
   fontWeight: 500,
   padding: "10px",
   borderRadius:"4px"
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
}));

export function AddPlan(props) {
  const classes = useStyles();
  const { open, onClose } = props;

  const handleCancelClick = () => {
    onClose();
  };

  const handleSaveClick = () => { };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>Add New Plan</Typography>
        <Grid container spacing={4} direction="column">

          <Grid item xs>
            <Stack spacing={4}>

              <NormalTextField label="Plan Title" />
              <Grid item xs>
                <Stack spacing={2} direction="row">
                  <NormalTextField label="Actual Amount" />
                  <NormalTextField label="Discounted Amount" />
                </Stack>
              </Grid>
              <Box>
                  <Stack className={classes.discountBox}>
                  7% discounted on this plan.
                  </Stack>
                </Box>
             
           <MegazinePlanCard/>

              <Box>
                <Stack>
                  <ItemButton type="primary" showIcon={true} >Add Item </ItemButton>
                </Stack>
              </Box>
              <Stack direction="row" spacing={2}>
                <HeaderButton type="secondary" onClick={handleCancelClick}>
                  Cancel
                </HeaderButton>
                <HeaderButton type="primary" onClick={handleSaveClick}>
                  Save
                </HeaderButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
